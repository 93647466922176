import { DropDownListItem } from '../../../controls/DropDownList';
import { FilterItemAction } from './types';
import { OnHoverTooltip } from '../../../common';
import { AlertOption } from '../../../../types/email-preferences/EmailPreferences';
import IconSVG from '../../../../styles/svg-icons';

const FilterActionLabel = {
    [FilterItemAction.MakeDefault]: 'Make default',
    [FilterItemAction.ResetDefault]: 'Reset default',
    [FilterItemAction.DiscardChanges]: 'Discard changes',
    [FilterItemAction.Delete]: <i className="icon icon-delete"></i>,
    [FilterItemAction.SetFilterForPopup]: '',
};

export interface FilterDropDownListItem extends DropDownListItem {
    modified: boolean;
    alertOption: AlertOption;
}

interface FiltersDropdownItemProps {
    item: FilterDropDownListItem;
    hovered: boolean;
    withAlerts?: boolean;
    onActionClick: (action: FilterItemAction, item: DropDownListItem) => void;
}

export const FiltersDropdownItem = ({
    item,
    hovered,
    withAlerts,
    onActionClick,
}: FiltersDropdownItemProps) => {
    const { default: isDefault, modified, alertOption, bwicAlertOption, dealerInventoryAlertOption } = item.payload;
    const isAlertTurned = 
        (alertOption && alertOption !== AlertOption.Never) ||
        (bwicAlertOption && bwicAlertOption !== AlertOption.Never) ||
        (dealerInventoryAlertOption && dealerInventoryAlertOption !== AlertOption.Never);
        
    const renderActionLink = (action: FilterItemAction, item: DropDownListItem) => (
        <a
            href="/"
            className="btn-link"
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onActionClick(action, item);
            }}
        >
            {FilterActionLabel[action]}
        </a>
    );

    const renderHoveredState = () => {
        if (modified) {
            return renderActionLink(FilterItemAction.DiscardChanges, item);
        }

        const defaultState = isDefault
            ? FilterItemAction.ResetDefault
            : FilterItemAction.MakeDefault;

        return (
            <>
                {renderActionLink(defaultState, item)}
                
                {withAlerts &&
                    <>
                        <span className="vertical-divider" />
                        <OnHoverTooltip overlay="Filter Alerts">
                            <button
                                className="btn-link"
                                onClick={e => {
                                    e.stopPropagation();
                                    onActionClick(FilterItemAction.SetFilterForPopup, item);
                                }}
                            >
                                <IconSVG name={isAlertTurned ? 'alert' : 'alert-off'} width="16" height="16" />
                            </button>
                        </OnHoverTooltip>
                    </>
                }
                <span className="vertical-divider" />
            </>
        );
    };

    const renderUnhoveredState = () => (
        <>
            {isDefault && (
                <>
                    <div className="default-filter-label">Default</div>
                    <span className="vertical-divider" />
                </>
            )}
            {withAlerts &&
                <>
                    <OnHoverTooltip overlay="Filter Alerts">
                        <button
                            className="btn-link"
                            onClick={e => {
                                e.stopPropagation();
                            }}
                        >
                            <IconSVG name={isAlertTurned ? 'alert' : 'alert-off'} width="16" height="16" />
                        </button>
                    </OnHoverTooltip>
                    <span className="vertical-divider" />
                </>
            }
        </>
    );

    return (
        <div className="flex-row text-sm">
            <OnHoverTooltip overlay={item.text} wrapperClass="text-ellipsis filter-name flex-item" overlayClassName="break-word">
                {item.text}
            </OnHoverTooltip> &nbsp;
            {item.modified && <div className="default-filter-label">(unsaved)</div>}

            <div className="flex-item-right flex-row flex-none">
                {hovered
                    ? renderHoveredState()
                    : renderUnhoveredState()
                }
                <div className="delete-btn">
                    {renderActionLink(FilterItemAction.Delete, item)}
                </div>
            </div>
        </div>
    );
};
