import cn from "classnames";
import { useState } from "react";
import { constants, roles } from "../../../../constants";
import IconSVG from "../../../../styles/svg-icons";
import { AmrTransaction } from "../../../../types/amr-pipeline/models/AmrTransaction";
import { Company } from "../../../../types/amr-pipeline/models/Company";
import { OriginatingTransaction } from "../../../../types/amr-pipeline/models/OriginatingTransaction";
import { OriginatingTransactionDocument } from "../../../../types/amr-pipeline/models/OriginatingTransactionDocument";
import {
    getCurrentIntexPositionIdForTransaction,
    hasLimitedAccess,
    transactionHasArrangerProSubscription,
} from "../../../../utils/amr-pipeline.utils";
import { OnHoverTooltip, Preloader } from "../../../common";
import { IntexLink } from "../../../common/IntexLink";
import { useIntexFile } from "./useIntexFile";
import { user } from "../../../../user";
import { ActionBlocker } from '../../../access/ActionBlocker';
import { SubscriptionFeature } from '../../../../types/billing/SubscriptionFeature';
import { FeatureButton } from '../../../access/FeatureButton';
import classNames from 'classnames';
import { TransactionType } from '../../../../types/amr-pipeline/enums/TransactionType';

interface Props {
    transaction: OriginatingTransaction | AmrTransaction;
    userCompany?: Company;
    disabled?: boolean;
    label?: string;
    placeholderIfEmpty?: boolean;
    secondaryPlaceholderColor?: boolean;
    tooltipOverlay?: string;
    asyncFileLoad?: boolean;
    onClick: (transaction: OriginatingTransaction) => void;
    onClickLink?: () => void;
}

export function IntexButton({
    transaction,
    userCompany,
    label = '',
    disabled = false,
    placeholderIfEmpty = false,
    secondaryPlaceholderColor = false,
    tooltipOverlay = "Intex",
    asyncFileLoad = true,
    onClick,
    onClickLink,
}: Props) {
    const [loading, setLoading] = useState(false);

    const onLoadSuccess = (intexFile?: OriginatingTransactionDocument) => {
        onClick({ ...transaction, intexFile } as OriginatingTransaction);
    };

    useIntexFile(transaction as OriginatingTransaction, loading, setLoading, onLoadSuccess);

    if (disabled) {
        return (
            <button className={'btn-link btn-link-intex'} disabled={disabled}>
                <IconSVG name="intex" width={16} height={16} />
            </button>
        );
    }

    const hasImAccess = user.hasFeatures(SubscriptionFeature.IssuanceMonitorHistoricalData);
    const limitedAccess = hasLimitedAccess(transaction, userCompany);
    const isMedia = user.hasRoles(roles.Media);
    const intexExists = (transaction as OriginatingTransaction).intexExists;
    const currentIntexPositionId = getCurrentIntexPositionIdForTransaction(transaction);
    const hasArrangerProSubscription = transactionHasArrangerProSubscription(transaction);

    const renderEmpty = () => placeholderIfEmpty ? (
        <span className={cn({ 'action-empty-placeholder': secondaryPlaceholderColor })}>
            {constants.emptyPlaceholder}
        </span>
    ) : null;

    if (!currentIntexPositionId && !intexExists) {
        return renderEmpty();
    }

    if (transaction.type === TransactionType.amr || limitedAccess || isMedia ||
        (!transaction.dealTicker && currentIntexPositionId && !intexExists)) {
        return currentIntexPositionId ? (
            <IntexLink positionId={currentIntexPositionId} onClick={onClickLink} text={label} />
        ) : renderEmpty();
    }

    if (loading) {
        return <Preloader inProgress small />;
    }

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();

        if (asyncFileLoad && intexExists && (hasImAccess || hasArrangerProSubscription)) {
            setLoading(true);
            return;
        }

        onClick(transaction as OriginatingTransaction);
    };

    return (
        <ActionBlocker feature={SubscriptionFeature.IntegrationsIntex}>
            {blocked => (
                <OnHoverTooltip wrapperClass="intex-link" overlay={tooltipOverlay} disabled={blocked}>
                    <FeatureButton
                        onClick={handleClick}
                        className={classNames('btn-link btn-link-intex', { 'btn-link-no-label': !label })}
                    >
                        <IconSVG name="intex" width={16} height={16} /> {label}
                    </FeatureButton>
                </OnHoverTooltip>
            )}
        </ActionBlocker>
    );
}
