import { sumBy } from 'lodash';
import { constants } from '../../constants';
import { OnHoverTooltip } from '../common';

interface ProportionBarItem {
    value: number;
    title: string;
    color: string;
}

interface ProportionBarProps {
    items: ProportionBarItem[];
    title?: string;
}

const lineWidth = 170;

export const ProportionBar = ({ items, title }: ProportionBarProps) => {
    const maxValue = sumBy(items, (i) => i.value);

    const renderTooltipOverlay = () =>
        items.map(i => (
            <div key={i.value + i.color} className="proportion-bar-row">
                <div className="proportion-bar-color" style={{ backgroundColor: i.color }}></div>
                <div className="proportion-bar-label">
                    {i.title}: {`${i.value || constants.emptyPlaceholder} ${title && i.value ? title : ''}`}
                </div>
            </div>
        ));

    return (
        <OnHoverTooltip
            overlay={renderTooltipOverlay()}
            placement="bottom"
            overlayClassName="proportion-bar-tooltip"
        >
            <div className="proportion-bar">
                <div className="proportion-bar-chart">
                    {items.map(i => {
                        const fillingPercent = ((i.value ?? 0) / maxValue) * lineWidth;

                        return (
                            <div
                                key={i.value + i.color}
                                className="proportion-bar-progress"
                                style={{ width: fillingPercent || 0, backgroundColor: i.color }}
                            />
                        );
                    })}
                </div>
            </div>
        </OnHoverTooltip>
    );
};
