import classNames from "classnames";
import { useContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { StatisticsAlertOption } from "../../../../../types/amr-pipeline/enums/AlertOption";
import { AlertOption } from "../../../../../types/email-preferences/EmailPreferences";
import { AppState } from "../../../../../types/state/AppState";
import { UserConfigState } from "../../../../../types/state/UserConfigState";
import { UserConfigFilter } from "../../../../../types/user-config/UserConfigFilter";
import { Popup, PopupBody, PopupFooter } from "../../../../controls";
import PipelineContext from "../../PipelineContext";
import { UserConfigType } from "../../../../../types/user-config/UserConfigType";
import { PipelineType } from "../../../../../types/amr-pipeline/enums/PipelineType";
import { ImEmailAlerts } from "./ImEmailAlerts";
import { routes } from "../../../../../constants";
import { Link } from "react-router-dom";
import { EmailPreferencesTab } from "../../../../profile/tabs/email-preferences/EmailPreferencesContent";
import { Preloader } from "../../../../common";
import { isEqual } from "lodash";

interface EmailAlertsPopupProps {
    onSave: (
        everySingleUpdate: AlertOption,
        newTransactionUpdate: AlertOption,
        weeklyStatistic: StatisticsAlertOption,
        filterConfig: UserConfigFilter[]
    ) => void;
    onClose: () => void;
}

export const ImEmailAlertsPopup = ({
    onSave,
    onClose,
}: EmailAlertsPopupProps) => {
    const { pipelineType } = useContext(PipelineContext);

    const filterType =
        pipelineType === PipelineType.ArrangerPipeline
            ? UserConfigType.apFilter
            : UserConfigType.imFilter;

    const imUserConfig = useSelector<AppState, UserConfigState>(
        (state) => state.imUserConfig
    );

    const { isUpdating, isDeleting } = imUserConfig;

    const imAlertOption = imUserConfig.imAlertConfig.value.alertOption;
    const newTransactionAlertOption = imUserConfig.newTransactionAlertConfig.value.alertOption;
    const weeklyStatsAlertOption = imUserConfig.weeklyStatsAlertConfig.value.emailOption;
    const filterList = imUserConfig.filtersConfig[filterType].value;

    const [singleUpdateReceiving, setSingleUpdateReceiving] = useState(imAlertOption);
    const [newTransactionReceiving, setNewTransactionReceiving] = useState(newTransactionAlertOption);
    const [statisticsReceiving, setStatisticsReceiving] = useState(weeklyStatsAlertOption);
    const [filterAlertsStatuses, setFilterAlertsStatuses] =
        useState(filterList);

    const handleSave = () =>
        onSave(singleUpdateReceiving, newTransactionReceiving, statisticsReceiving, filterAlertsStatuses);

    const withAlertsFilters = !!filterAlertsStatuses.length;

    const isInitialFilter = useMemo(() =>
        isEqual(filterList, filterAlertsStatuses),
        [filterList, filterAlertsStatuses]
    );

    const isInitialAlertsState = 
        singleUpdateReceiving === imAlertOption &&
        newTransactionReceiving === newTransactionAlertOption &&
        statisticsReceiving === weeklyStatsAlertOption &&
        isInitialFilter;

    const inProgress = isUpdating || isDeleting;
    const isSaveDisabled = isInitialAlertsState || inProgress;

    return (
        <Popup
            title="Deals Alerts"
            onClose={onClose}
            modalClass={classNames("modal-email-alerts", {
                "modal-email-alerts-empty": !withAlertsFilters,
            })}
        >
            <PopupBody className="notifications-center">
                <ImEmailAlerts
                    singleUpdate={singleUpdateReceiving}
                    onChangeSingleUpdate={setSingleUpdateReceiving}
                    newTransactionUpdate={newTransactionReceiving}
                    onChangeNewTransactionUpdate={setNewTransactionReceiving}
                    weeklyStatistic={statisticsReceiving}
                    onChangeWeeklyStatistic={setStatisticsReceiving}
                    filterAlerts={filterAlertsStatuses}
                    onChangeFilterAlerts={setFilterAlertsStatuses}
                />
            </PopupBody>
            <PopupFooter>
                <Link to={routes.profileNotificationsTab(EmailPreferencesTab.IssuanceMonitor)} className="btn-link btn-email-alerts">
                    Manage Alerts
                </Link>
                <button className="btn btn-ghost" onClick={onClose}>
                    Cancel
                </button>
                <button className="btn btn-main" onClick={handleSave} disabled={isSaveDisabled}>
                    Save {inProgress && <Preloader small inProgress />}
                </button>
            </PopupFooter>
        </Popup>
    );
};
