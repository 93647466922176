import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { applyDisabledSelector, issuanceMonitorFilterSelector } from '../../../selectors/amr-pipeline.selector';
import PipelineContext from './PipelineContext';
import { PipelineType } from '../../../types/amr-pipeline/enums/PipelineType';
import { FilterManagementForm } from './filters-management/types';
import { imUserConfigActions } from '../../../actions';
import { AppState } from '../../../types/state/AppState';
import { UserConfigType } from '../../../types/user-config/UserConfigType';
import { Filters } from '../../filters/Filters';
import { FiltersConfig } from '../../../types/user-config/UserConfig';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { BlockedFeatureTooltipText } from '../../access/BlockedFeatureText';

interface IPipelineFilter {
    isSearching: boolean;
    allowCollapsing?: boolean;
    onApply: () => void;
    onReset: () => void;
    hiddenFilters: string[];
    onVisibilityChange?: (filterName: string) => void;
}

export const PipelineFilter = ({
    isSearching,
    onApply,
    onReset,
    allowCollapsing = true,
    hiddenFilters = [],
    onVisibilityChange,
}: IPipelineFilter) => {
    const { pipelineType, filtersCollapseStatus, setFiltersCollapseStatus, withBlockedSearchAndFilter } =
        useContext(PipelineContext);

    const isUpdating = useSelector<AppState, boolean>(state => state.imUserConfig.isUpdating);
    const filtersConfig = useSelector<AppState, FiltersConfig>(state => state.imUserConfig.filtersConfig);

    const filterType =
        pipelineType !== PipelineType.ArrangerPipeline ? UserConfigType.imFilter : UserConfigType.apFilter;

    const isFilterCollapsed = filtersCollapseStatus[pipelineType];

    const dispatch = useDispatch();

    const { filter, filterChanged, filterModified, visibleFilters, selectedFilterReferenceName } = useSelector(
        issuanceMonitorFilterSelector(pipelineType),
    );

    const applyDisabled = useSelector(applyDisabledSelector(pipelineType));

    useEffect(() => {
        if (selectedFilterReferenceName) {
            setFiltersCollapseStatus(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilterReferenceName]);

    const handleApplyFilter = () => {
        onApply();
    };

    const handleClearFilter = () => onReset();

    const handleSaveFilter = (data: FilterManagementForm) => {
        const name = data.name.trim();

        dispatch(
            imUserConfigActions.createUserFilter(pipelineType, {
                name,
                default: data.makeDefault,
                alertOption: data.receiveEmailNotification,
            }),
        );
    };

    const handleUpdateFilter = (data: FilterManagementForm) => {
        const name = data.name.trim();

        dispatch(
            imUserConfigActions.updateUserFilter(pipelineType, {
                referenceName: data.referenceName || '',
                name,
                default: data.makeDefault,
                alertOption: data.receiveEmailNotification,
            }),
        );
    };

    return (
        <Filters
            isSearching={isSearching}
            onApply={handleApplyFilter}
            onReset={handleClearFilter}
            allowCollapsing={allowCollapsing}
            hiddenFilters={hiddenFilters}
            onVisibilityChange={onVisibilityChange}
            filterType={pipelineType}
            filtersCollapseStatus={isFilterCollapsed}
            setFiltersCollapseStatus={setFiltersCollapseStatus}
            withBlockedSearchAndFilter={withBlockedSearchAndFilter}
            isUpdating={isUpdating}
            filter={filter}
            filters={filtersConfig[filterType]?.value}
            selectedFilterReferenceName={selectedFilterReferenceName}
            visibleFilters={visibleFilters}
            filterChanged={filterChanged}
            filterModified={filterModified}
            applyDisabled={applyDisabled}
            onFilterSave={handleSaveFilter}
            onFilterUpdate={handleUpdateFilter}
            saveFilterBlockerFeature={SubscriptionFeature.IssuanceMonitorFullAccess}
            advancedFiltersBlockerFeature={SubscriptionFeature.IssuanceMonitorFullAccess}
            advancedFiltersBlockerText={<BlockedFeatureTooltipText />}
            filterConfigShowEmailNotification={pipelineType !== PipelineType.ArrangerPipeline}
        />
    );
};
