import { Popup, PopupBody, PopupFooter } from "../../controls";
import { OnHoverTooltip } from '../../common';
import { SyndicateContacts } from "../../../types/amr-pipeline/models/SyndicateContacts";
import { Contact } from "../../../types/amr-pipeline/models/Contact";
import { Table } from "../../bidding/common/table";
import { ColumnBuilder } from "../../bidding/common/table/columns/column-builder/ColumnBuilder";
import { IColumnDefinition } from "../../bidding/common/table/types/ColumnDefinition";
import { constants } from "../../../constants";
import IconSVG from '../../../styles/svg-icons';
import { nameUtils } from "../../../utils";
import { ContactType } from '../../../types/amr-pipeline/enums/ContactType';

const getTableColumns = () => {
    const columnDefinitions: IColumnDefinition<Contact>[] = [
        {
            columnKey: "fullName",
            renderColumnHeaderContent: () => "Full Name",
            renderColumnContent: ({ firstName, lastName }) => {
            const fullName = nameUtils.getFullName(firstName, lastName);
            return (
                <OnHoverTooltip overlay={fullName} disabled={!(firstName || lastName)}>
                    <span>{fullName}</span>
                </OnHoverTooltip>
            );
        },
            className: "data-list-cell-lg",
        },
        {
            columnKey: 'distribution-list',
            renderColumnHeaderContent: () => '',
            renderColumnContent: ({ type }) => {
                if (type === ContactType.DistributionList) {
                    return <IconSVG name="distrib" width={16} height={16} />
                }

                if (type === ContactType.HeadOfDesk) {
                    return <IconSVG name="head-of-desk" width={16} height={16} />
                }
            },
            className: 'data-list-cell-xxxs',
        },
        {
            columnKey: 'title',
            renderColumnHeaderContent: () => 'Title',
            renderColumnContent: ({ type, title }) => {
                if (type === ContactType.DistributionList) {
                    return (
                        <OnHoverTooltip overlay="Distribution List" placement="bottom">
                            <span>Distribution List</span>
                        </OnHoverTooltip>
                    );
                }

                if (type === ContactType.HeadOfDesk) {
                    return (
                        <OnHoverTooltip overlay="Head of Desk" placement="bottom">
                            <span>Head of Desk</span>
                        </OnHoverTooltip>
                    );
                }

                if (title) {
                    return (
                        <OnHoverTooltip overlay={title} placement="bottom">
                            <span>{title}</span>
                        </OnHoverTooltip>
                    );
                }

                return constants.emptyPlaceholder;
            },
            headerClassName: 'data-list-cell-md data-list-cell-full-name',
            bodyClassName: 'data-list-cell-md',
        },
        {
            columnKey: "email",
            renderColumnHeaderContent: () => "Email",
            renderColumnContent: ({ email }) =>
                email ? (
                    <OnHoverTooltip overlay={email}>
                        <a href={`mailto:${email}`}>{email}</a>
                    </OnHoverTooltip>
                ) : constants.emptyPlaceholder,
            className: "data-list-cell-xl",
        },
        {
            columnKey: "office",
            renderColumnHeaderContent: () => "Office",
            renderColumnContent: ({ officePhoneNumber }) =>
                officePhoneNumber || constants.emptyPlaceholder,
            className: "data-list-cell-sm",
        },
        {
            columnKey: "mobile",
            renderColumnHeaderContent: () => "Mobile",
            renderColumnContent: ({ mobilePhoneNumber }) =>
                mobilePhoneNumber || constants.emptyPlaceholder,
            className: "data-list-cell-sm",
        },
    ];

    return columnDefinitions.map((c) => new ColumnBuilder(c));
};

interface SyndicatePopupProps {
    syndicateContacts: SyndicateContacts;
    onClose: () => void;
}

export function SyndicatePopup({ syndicateContacts, onClose }: SyndicatePopupProps) {
    const { arrangerName, contacts } = syndicateContacts;

    return (
        <Popup
            modalClass="modal-syndicate-contacts"
            title="Syndicate Contacts"
            renderTitle={<span className="name-tag">{arrangerName}</span>}
            onClose={onClose}
        >
            <PopupBody>
                <Table dataItems={contacts} columns={getTableColumns()} className="data-list-striped" />
            </PopupBody>
            <PopupFooter>
                <button className="btn btn-ghost" onClick={onClose}>
                    Close
                </button>
            </PopupFooter>
        </Popup>
    );
}
