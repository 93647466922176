import { AlertOption } from "../../../../../types/email-preferences/EmailPreferences";
import { SubscriptionFeature } from "../../../../../types/billing/SubscriptionFeature";
import { UserConfigFilter } from "../../../../../types/user-config/UserConfigFilter";
import { ActionBlocker } from "../../../../access/ActionBlocker";
import { RadioButton } from "../../../../controls";

interface Props {
    option: AlertOption;
    imFilterValues: UserConfigFilter[];
    onChange: (filters: UserConfigFilter[]) => void;
    disabled?: boolean;
    requiredFeature?: SubscriptionFeature;
}
export function ImTableHeaderRadioButton({
    option,
    imFilterValues,
    onChange,
    disabled,
    requiredFeature
}: Props) {

    const selected = imFilterValues?.some((x) => x.alertOption === option);
    const partially = imFilterValues?.some((x) => x.alertOption !== option);

    const handleClick = () => {
        const updatedStatus = imFilterValues?.map((v) => ({
            ...v,
            alertOption: option,
        }));
        onChange(updatedStatus);
    };

    return (
        <ActionBlocker feature={requiredFeature}>
            {blocked =>
                <RadioButton
                    disabled={disabled || blocked}
                    name={`global-${option}`}
                    label={option}
                    checked={blocked ? option === AlertOption.Never: selected}
                    partially={!blocked && partially}
                    onClick={handleClick}
                    className="align-top"
                    onChange={() => { }}
                />
            }
        </ActionBlocker>
    );
}
