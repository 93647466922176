import { useState } from "react";
import { useSelector } from "react-redux";
import { AlertOption } from "../../../../../types/email-preferences/EmailPreferences";
import { AppState } from "../../../../../types/state/AppState";
import { UserConfigState } from "../../../../../types/state/UserConfigState";
import { Popup, PopupBody, PopupFooter } from "../../../../controls";
import { ApEmailAlerts } from "./ApEmailAlerts";
import { routes } from "../../../../../constants";
import { Link } from "react-router-dom";
import { EmailPreferencesTab } from "../../../../profile/tabs/email-preferences/EmailPreferencesContent";
import { Preloader } from "../../../../common";

interface EmailAlertsPopupProps {
    onSave: (upcomingOption: AlertOption) => void;
    onClose: () => void;
}

export const ApEmailAlertsPopup = ({
    onSave,
    onClose,
}: EmailAlertsPopupProps) => {
    const imUserConfig = useSelector<AppState, UserConfigState>(
        (state) => state.imUserConfig
    );

    const { isUpdating, isDeleting } = imUserConfig;

    const initialUpcomingTransaction = imUserConfig.apAlertConfig.value.alertOption;

    const [upcomingTransaction, setUpcomingTransaction] =
        useState(initialUpcomingTransaction);

    const handleSave = () => onSave(upcomingTransaction);

    const isInitialAlertsState = upcomingTransaction === initialUpcomingTransaction;
    const inProgress = isUpdating || isDeleting;

    return (
        <Popup
            title="Arranger Pipeline Alerts"
            onClose={onClose}
            modalClass="modal-email-alerts modal-email-alerts-arranger-pipeline"
        >
            <PopupBody className="notifications-center">
                <ApEmailAlerts
                    upcomingAlert={upcomingTransaction}
                    onChangeUpcomingAlert={setUpcomingTransaction}
                />
            </PopupBody>
            <PopupFooter>
                <Link
                    to={routes.profileNotificationsTab(
                        EmailPreferencesTab.ArrangerPipeline
                    )}
                    className="btn-link btn-email-alerts"
                >
                    Manage Alerts
                </Link>
                <button className="btn btn-ghost" onClick={onClose}>
                    Cancel
                </button>
                <button
                    className="btn btn-main"
                    onClick={handleSave}
                    disabled={isInitialAlertsState || inProgress}
                >
                    Save {inProgress && <Preloader small inProgress />}
                </button>
            </PopupFooter>
        </Popup>
    );
};
