import React from 'react';
import { OriginatingTransaction } from '../../../../types/amr-pipeline/models/OriginatingTransaction';
import { commonPipelineColumns } from './columns/common-columns';
import { PipelineList } from '../PipelineList';
import { AmrTransaction } from '../../../../types/amr-pipeline/models/AmrTransaction';
import { UserConfigColumn } from '../../../../types/user-config/UserConfigColumn';
import { PipelineEmptyPlaceholder } from '../PipelineEmptyPlaceholder';

interface Props {
    columnsConfig: UserConfigColumn[];
    transactions: (OriginatingTransaction | AmrTransaction)[];
    onRedirect: () => void;
}

export const DealView = ({ columnsConfig, transactions, onRedirect }: Props) => {
    return transactions.length
        ? <PipelineList className={'pipeline-list-deals'} columns={commonPipelineColumns(columnsConfig)} dataItems={transactions} />
        : <PipelineEmptyPlaceholder onRedirect={onRedirect} />
};
