import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AlertOption } from '../../../../types/email-preferences/EmailPreferences';
import { AppState } from '../../../../types/state/AppState';
import { Pluralize, Preloader } from '../../../common';
import { Popup, PopupBody, PopupFooter } from '../../../controls';
import { EmailPreferencesRadioGroup } from '../../../profile/tabs/email-preferences/tabs/EmailPreferencesRadioGroup';
import { DropDownListItem } from '../../../controls/DropDownList';
import { alertOptions as defaultAlertOptions } from '../../../../constants/amr-pipeline/alertOptions';
import { defaultAlertOptionToolitps } from '../email-alerts/issuance-monitor/FilterUpdatesTable';

interface Props {
    filter: DropDownListItem;
    onClose: () => void;
    onConfirm: (alertOption: AlertOption) => void;
    description: string;
    alertOptionInfo?: { [key in AlertOption]?: string };
    alertOptions?: AlertOption[];
}

export const FilterAlertsPopup = ({ filter, onClose, onConfirm, description, alertOptionInfo = defaultAlertOptionToolitps, alertOptions = defaultAlertOptions }: Props) => {
    const isUpdating = useSelector((state: AppState) => state.imUserConfig.isUpdating);
    const isDeleting = useSelector((state: AppState) => state.imUserConfig.isDeleting);

    const inProgress = isUpdating || isDeleting;

    const [alertOption, setAlertOption] = useState<AlertOption>(filter.payload.alertOption);
    const [saving, setSaving] = useState(false);

    const filterOptionsCount = Object.keys(filter.payload.filter).length;

    useEffect(() => {
        if (!inProgress && saving) {
            onClose();
            setSaving(false);
        }
    }, [inProgress, saving, onClose]);

    const handleSave = () => {
        setSaving(true);
        onConfirm(alertOption);
    };

    return (
        <Popup
            title={<span className="title">Saved Filter Alerts</span>}
            onClose={onClose}
            modalClass="modal-email-preferences"
        >
            <PopupBody className="notifications-center">
                <div className="controls-wrap">
                    <EmailPreferencesRadioGroup
                        value={alertOption}
                        disabled={inProgress}
                        alertOptions={alertOptions}
                        alertOptionInfo={alertOptionInfo}
                        groupNameIndentifier="filter-alerts"
                        title={<>{filter.text} (<Pluralize count={filterOptionsCount} singular="filter" />)</>}
                        description={description}
                        onChange={setAlertOption}
                    />
                </div>
            </PopupBody>
            <PopupFooter>
                <button className="btn btn-ghost" onClick={onClose}>
                    Cancel
                </button>
                <button className="btn btn-main" onClick={handleSave}>
                    Save {inProgress && <Preloader small inProgress />}
                </button>
            </PopupFooter>
        </Popup>
    );
};
