import { IssueTransactionVolumeWidget } from '../../dashboard/issueTransactionVolume/IssueTransactionVolumeWidget';
import { TopArrangersWidget } from '../../dashboard/topIssuers/TopArrangersWidget';
import { TopIssuersWidget } from '../../dashboard/topIssuers/TopIssuersWidget';
import { UsBslEsgNewIssueWidget } from '../../dashboard/usBslEsgNewIssue/UsBslEsgNewIssueWidget';
import { TopDebutDeals } from '../../dashboard/topIssuers/TopDebutDeals';
import { TopStaticDeals } from '../../dashboard/topIssuers/TopStaticDeals';
import { UsBslEuCompliantDealsWidget } from '../../dashboard/usBslEuCompliantDeals/UsBslEuCompliantDealsWidget';
import { ShortLongDatedDealsWidget } from '../../dashboard/shortLongDatedDeals/ShortLongDatedDealsWidget';
import { IssuanceSpreadWidget } from '../../dashboard/issuanceSpread/IssuanceSpreadWidget';
import { TopTrusteesWidget } from '../../dashboard/topIssuers/TopTrusteesWidget';
import { UsBslNewIssueWeeklySpreads } from '../../dashboard/usBslNewIssueSpreads/UsBslNewIssueWeeklySpreads';
import { pipelineRequestCriteria } from '../../../constants';

interface PipelineDashboardProps {
    onWidgetItemClick: () => void;
}

export const PipelineDashboard = ({ onWidgetItemClick }: PipelineDashboardProps) => {
    return (
        <div className="container-flex pipeline-dashboard-charts">
            <div className="dashboard-row flex-row">
                <IssueTransactionVolumeWidget />
                <TopIssuersWidget onListItemClick={onWidgetItemClick} />
            </div>
            <div className="dashboard-row flex-row">
                <IssuanceSpreadWidget withSlider />
                <TopArrangersWidget onListItemClick={onWidgetItemClick} />
            </div>
            <div className="dashboard-row flex-row">
                <UsBslEsgNewIssueWidget />
                <TopTrusteesWidget onListItemClick={onWidgetItemClick} />
            </div>
            <div className="dashboard-row flex-row">
                <UsBslEuCompliantDealsWidget />
                <TopStaticDeals onListItemClick={onWidgetItemClick} />
            </div>
            <div className="dashboard-row flex-row">

            </div>
            <div className="dashboard-row flex-row">
                <ShortLongDatedDealsWidget
                    startDate={pipelineRequestCriteria.shortLongDatedDeals.startDateDashboard}
                />
                <TopDebutDeals onListItemClick={onWidgetItemClick} />
            </div>
            <div className="dashboard-row flex-row">
                <UsBslNewIssueWeeklySpreads />
            </div>
        </div>
    );
}
