import { useMemo } from 'react';
import { PipelineView } from '../types/PipelineView';
import { IssuanceMonitorTopPanel } from './IssuanceMonitorTopPanel';
import { Tab, PipelineTabs } from './PipelineTabs';
import { useSelector } from 'react-redux';
import { IssuanceMonitorTab } from '../types/PipelineTabTypes';
import { user } from '../../../user';
import { roles } from '../../../constants';
import { hasIOIsAccess } from '../../../utils/amr-pipeline.utils';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { arrangerPipelineDealsSelector } from '../../../selectors/amr-pipeline.selector';

interface IssuanceMonitorLayoutProps {
    tab: IssuanceMonitorTab;
    setTab: (tab: IssuanceMonitorTab) => void;
    viewMode: PipelineView;
    setViewMode: (viewMode: PipelineView) => void;
    children: React.ReactNode;
}

const Tabs = [
    { title: 'Deals', tab: IssuanceMonitorTab.Deals },
    { title: 'Dashboard', tab: IssuanceMonitorTab.Dashboard },
    { title: 'Arranger Pipeline', tab: IssuanceMonitorTab.ArrangerPipeline },
    { title: 'IOIs', tab: IssuanceMonitorTab.IOIs },
];

export const IssuanceMonitorLayout = ({ tab, setTab, viewMode, setViewMode, children }: IssuanceMonitorLayoutProps) => {
    const ioisAccess = hasIOIsAccess();

    const anyPipelineExists = useSelector(arrangerPipelineDealsSelector).anyPipelineExists;

    const withImSubscription = user.hasFeatures(SubscriptionFeature.IssuanceMonitorFullAccess);
    const isBD = user.hasRoles(...roles.bd());

    const isArrangerPipelineDisabled = !withImSubscription && !anyPipelineExists && !isBD;

    const isTabDisabled = (tab: IssuanceMonitorTab) => tab === IssuanceMonitorTab.ArrangerPipeline && isArrangerPipelineDisabled;

    const accessibleTabs = useMemo(() => Tabs.filter(({ tab }) => ioisAccess ? true : tab !== IssuanceMonitorTab.IOIs), [ioisAccess]);

    return (
        <>
            <div className="sub-header sub-header-pipeline sub-header-head">
                <div className="sub-header-row flex-row type01">
                    <PipelineTabs className="tabs-large" disabled={true} value={tab} onChange={setTab}>
                        {accessibleTabs.map(({ title, tab }) => (
                            <Tab
                                disabled={isTabDisabled(tab)}
                                title={title}
                                key={tab}
                                value={tab}
                            />
                        ))}
                    </PipelineTabs>

                    {tab !== IssuanceMonitorTab.Dashboard && (
                        <IssuanceMonitorTopPanel tab={tab} viewMode={viewMode} onViewModeChange={setViewMode} />
                    )}
                </div>
            </div>
            {children}
        </>
    );
};
