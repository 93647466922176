import React from "react";
import { constants } from "../../../constants";
import moment from "moment";

interface Props {
    nonCallPeriodEnd?: Date;
    reinvestmentPeriodEnd?: Date;
}

export const PeriodPopover = ({
    nonCallPeriodEnd,
    reinvestmentPeriodEnd,
}: Props) => {
    return (
        <>
            <div className="block-information-row">
                <div className="block-information-col block-information-col-titles">
                    Non-Call End
                </div>
                <div className="block-information-col block-information-col-text">
                    {nonCallPeriodEnd
                        ? moment(nonCallPeriodEnd).format(constants.dateFormat)
                        : constants.emptyPlaceholder}
                </div>
            </div>
            <div className="block-information-row">
                <div className="block-information-col block-information-col-titles">
                    RI End
                </div>
                <div className="block-information-col block-information-col-text">
                    {reinvestmentPeriodEnd
                        ? moment(reinvestmentPeriodEnd).format(constants.dateFormat)
                        : constants.emptyPlaceholder}
                </div>
            </div>
        </>
    );
};
