interface Props {
    status: string;
    className?: string;
}

const StatusLabel = ({ status, className }: Props) => {
    return (
        <div className={`label-status label-status-${String(status).toLowerCase().replace(' ', '-')} ${className || ''}`}>
            {status}
        </div>
    )
}

export default StatusLabel
