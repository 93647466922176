import { AlertOption } from "../../../../../types/email-preferences/EmailPreferences";
import { RadioButton } from "../../../../controls";
import {
    alertOptionTooltipText,
    alertOptions,
} from "../../../../../constants/amr-pipeline/alertOptions";

import { SubscriptionFeature } from "../../../../../types/billing/SubscriptionFeature";
import { ActionBlocker } from "../../../../access/ActionBlocker";
import EmailInfoTooltip from "../../../../common/EmailInfoTooltip";

const apAlertOptionTooltipText = {
    ...alertOptionTooltipText,
    [AlertOption.Instant]: "Alerts are sent once transactions are published",
};

interface Props {
    disabled?: boolean;
    upcomingAlert?: AlertOption;
    onChangeUpcomingAlert: (option: AlertOption) => void;
}

export const ApEmailAlerts = ({
    disabled,
    upcomingAlert = AlertOption.Never,
    onChangeUpcomingAlert,
}: Props) => {
    return (
        <div className="controls-wrap">
            <div className="controls-wrap-head">
                <h4 className="controls-wrap-title">
                    New Upcoming Transactions
                </h4>
                <div className="controls-wrap-radio">
                    {alertOptions.map((alertOption) => (
                        <div key={alertOption} className="controls-radio-item">
                            <ActionBlocker
                                feature={
                                    SubscriptionFeature.IssuanceMonitorFullAccess
                                }
                            >
                                {(blocked) => (
                                    <RadioButton
                                        disabled={blocked || disabled}
                                        name={`upcoming-transaction-${alertOption}`}
                                        label={alertOption}
                                        checked={
                                            blocked
                                                ? alertOption ===
                                                  AlertOption.Never
                                                : alertOption === upcomingAlert
                                        }
                                        onChange={() =>
                                            onChangeUpcomingAlert(alertOption)
                                        }
                                    />
                                )}
                            </ActionBlocker>
                            {alertOption !== AlertOption.Never && (
                                <EmailInfoTooltip
                                    overlayText={
                                        apAlertOptionTooltipText[alertOption as keyof typeof apAlertOptionTooltipText]
                                    }
                                />
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <p className="controls-wrap-info">
                Receive an email when new upcoming transactions are published on
                Arranger Pipeline.
            </p>
        </div>
    );
};
