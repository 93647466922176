import { Dispatch } from "react";
import classNames from "classnames";
import { constants, pipelineColumnLabels } from "../../../../../constants";
import IconSVG from "../../../../../styles/svg-icons";
import { TransactionStatus } from "../../../../../types/amr-pipeline/enums/TransactionStatus";
import { TransactionType } from "../../../../../types/amr-pipeline/enums/TransactionType";
import { AmrTransaction } from "../../../../../types/amr-pipeline/models/AmrTransaction";
import { Company } from "../../../../../types/amr-pipeline/models/Company";
import { OriginatingTransaction } from "../../../../../types/amr-pipeline/models/OriginatingTransaction";
import { isBrokerDealersOwnTransaction } from "../../../../../utils/amr-pipeline.utils";
import { IColumnDefinition } from "../../../../bidding/common/table/types/ColumnDefinition";
import { OnHoverTooltip } from "../../../../common";
import { TransactionHistoryLink } from "../../../common/TransactionHistoryLink";
import { ImSubscriptionActionBlocker } from "../../../subscription/ImSubscriptionActionBlocker";
import { PipelineColumn } from "../../../types/PipelineColumn";
import { IssuanceMonitorTab } from "../../../types/PipelineTabTypes";
import { IntexButton } from "../../IntexButton/IntexButton";
import { OptionsButton } from "../../OptionsButton";
import { FilterRelatedTransactionsIcon } from '../../../common/FilterRelatedTransactionsIcon';
import { TransactionAlertsButton } from "../../../common/transactionAlerts/TransactionAlertsButton";

export const shareOptionColumnDefinition: IColumnDefinition<
    OriginatingTransaction | AmrTransaction
> = {
    columnKey: PipelineColumn.shareOption,
    renderColumnHeaderContent: () => "",
    renderColumnContent: (
        transaction,
        context: {
            userCompany: Company;
            setTransactionToShare: (
                transaction: OriginatingTransaction | AmrTransaction
            ) => void;
        }
    ) => {
        const { userCompany, setTransactionToShare } = context;
        const showShare = isBrokerDealersOwnTransaction(
            transaction,
            userCompany
        );

        return (
            <div className="action-row">
                <div className="action-col">
                    {showShare ? (
                        <OnHoverTooltip overlay="Share Transaction">
                            <div
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setTransactionToShare(transaction);
                                }}
                                className="btn-link"
                            >
                                <IconSVG name="share" width={16} height={16} />
                            </div>
                        </OnHoverTooltip>
                    ) : <span className="action-empty-placeholder">{constants.emptyPlaceholder}</span>}
                </div>
            </div>
        );
    },
    headerClassName: "data-list-cell-xxxs data-list-cell-actions padding-r-0 text-right",
    bodyClassName: "data-list-cell-xxxs data-list-cell-actions padding-r-0 text-right",
    stickRight: true,
};

export const buildOptionsColumnDefinition = (isBdOrSeller: boolean): IColumnDefinition<
    OriginatingTransaction | AmrTransaction
> => {
    return {
        columnKey: PipelineColumn.optionsAction,
        renderColumnHeaderContent: () =>
            pipelineColumnLabels[PipelineColumn.options],
        renderColumnContent: (
            transaction,
            context: {
                userCompany: Company;
                dispatch: Dispatch<any>;
                setTab: (tab: IssuanceMonitorTab) => void;
                setTransactionForIntex: (
                    transaction: OriginatingTransaction
                ) => void;
                setTransactionForAlerts: (transaction: OriginatingTransaction) => void;
            }
        ) => {
            const {
                referenceName,
                dealReferenceName,
                dealLegalName,
                numberOfDealPublishedTransactions,
                status,
                type,
                version,
            } = transaction;
            const { userCompany, setTab, setTransactionForIntex, setTransactionForAlerts } = context;
            const isOriginatingTransaction = type !== TransactionType.amr;
            const showHistory =
                status !== TransactionStatus.Draft && isOriginatingTransaction;

            return (
                <div className="action-row">
                    <div className="action-col">
                        {numberOfDealPublishedTransactions > 1 ? (
                            <ImSubscriptionActionBlocker>
                                {blocked => (
                                    <FilterRelatedTransactionsIcon
                                        dealLegalName={dealLegalName}
                                        dealReferenceName={dealReferenceName}
                                        onClick={() => setTab(IssuanceMonitorTab.Deals)}
                                        disabled={blocked}
                                    />
                                )}
                            </ImSubscriptionActionBlocker>
                        ) : <span className="action-empty-placeholder">{constants.emptyPlaceholder}</span>}
                    </div>
                    <div className="action-col">
                        {isOriginatingTransaction && status !== TransactionStatus.Closed ? (
                            <div onClick={e => e.stopPropagation()}>
                                <TransactionAlertsButton
                                    transaction={transaction}
                                    onClick={() => setTransactionForAlerts(transaction as OriginatingTransaction)}
                                />
                            </div>
                        ) : <span className="action-empty-placeholder">{constants.emptyPlaceholder}</span>}
                    </div>
                    <div className="action-col">
                        {showHistory ? (
                            <ImSubscriptionActionBlocker>
                                {blocked => (
                                    <div onClick={e => e.stopPropagation()}>
                                        <TransactionHistoryLink
                                            referenceName={referenceName}
                                            disabled={blocked}
                                            dealReferenceName={dealReferenceName}
                                            label={false}
                                            version={version}
                                        />
                                    </div>
                                )}
                            </ImSubscriptionActionBlocker>
                        ) : <span className="action-empty-placeholder">{constants.emptyPlaceholder}</span>}
                    </div>
                    <div className="action-col">
                        <IntexButton
                            transaction={transaction}
                            userCompany={userCompany}
                            onClick={setTransactionForIntex}
                            placeholderIfEmpty
                            secondaryPlaceholderColor
                        />
                    </div>
                </div>
            );
        },
        className: classNames("padding-r-0 data-list-cell-actions text-right", {
            'data-list-cell-lg-02': !isBdOrSeller,
            'data-list-cell-md': isBdOrSeller,
        }),
        stickRight: true,
    };
}

export const optionsButtonColumnDefinition: IColumnDefinition<
    OriginatingTransaction | AmrTransaction
> = {
    columnKey: PipelineColumn.optionButton,
    renderColumnHeaderContent: () => "",
    renderColumnContent: (
        transaction,
        context: {
            userCompany: Company;
        }
    ) => {
        return (
            <span onClick={(e) => e.stopPropagation()}>
                <OptionsButton
                    transaction={transaction}
                    userCompany={context.userCompany}
                />
            </span>
        );
    },
    headerClassName: "data-list-cell-sm-01 padding-l-0 data-list-cell-actions-btn text-right",
    bodyClassName: "data-list-cell-sm-01 padding-l-0 data-list-cell-actions-btn text-right",
    stickRight: true,
};
