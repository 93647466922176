import { EmptyPlaceholder } from '../common';

export const IssuanceMonitorNoData = () => {
    return (
        <div className="container-pipeline">
            <div className="pipeline-table">
                <EmptyPlaceholder text="There is no information to display." />
            </div>
        </div>
    );
};
