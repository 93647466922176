import { IColumnDefinition } from "../../../../bidding/common/table/types/ColumnDefinition";
import { ColumnBuilder } from "../../../../bidding/common/table/columns/column-builder/ColumnBuilder";
import { RadioButton } from "../../../../controls";
import { UserConfigFilter } from "../../../../../types/user-config/UserConfigFilter";
import { AlertOption } from "../../../../../types/email-preferences/EmailPreferences";
import { Pluralize } from "../../../../common";
import { OnHoverTooltip } from '../../../../common';
import EmailInfoTooltip from "../../../../common/EmailInfoTooltip";
import { ImTableHeaderRadioButton } from "./ImTableHeaderRadioButton";
import { ActionBlocker } from "../../../../access/ActionBlocker";

export enum FilterColumnsKeys {
    FilterName = 'FilterName',
    Never = 'Never',
    Daily = 'Daily',
    Instant = 'Instant'
}

const filterColumnDefinitions: IColumnDefinition<UserConfigFilter>[] = [
    {
        columnKey: FilterColumnsKeys.FilterName,
        renderColumnHeaderContent: () => "Filter Name",
        renderColumnContent: (filterConfigValue) => {
            const filterNameText = (
                <>
                    {`${filterConfigValue.name} `}
                    <Pluralize
                        count={Object.keys(filterConfigValue.filter).length}
                        singular="filter"
                        template="({{count}} {{text}})"
                    />
                </>
            )
            return (
                <div className="filter-name-text text-ellipsis">
                    <OnHoverTooltip overlay={filterNameText}>
                        {filterNameText}
                    </OnHoverTooltip>
                </div>
            )
        },
        className: "data-list-cell-xxxl",
    }, {
        columnKey: FilterColumnsKeys.Never,
        renderColumnHeaderContent: ({
            filtersStatuses,
            setFiltersStatuses,
            disabled,
            requiredFeature
        }) => (
            <ImTableHeaderRadioButton
                option={AlertOption.Never}
                imFilterValues={filtersStatuses}
                onChange={setFiltersStatuses}
                disabled={disabled}
                requiredFeature={requiredFeature}
            />
        ),
        renderColumnContent: (filterConfigValue, { disabled, requiredFeature, onColumnClick }) => (
            <ActionBlocker feature={requiredFeature}>
                {blocked =>
                    <RadioButton
                        className="align-top"
                        disabled={disabled || blocked}
                        name={filterConfigValue.name}
                        checked={blocked || filterConfigValue.alertOption === AlertOption.Never}
                        onChange={() =>
                            onColumnClick(
                                filterConfigValue.referenceName,
                                AlertOption.Never
                            )
                        }
                    />}
            </ActionBlocker>
        ),
        className: "",
    }, {
        columnKey: FilterColumnsKeys.Daily,
        renderColumnHeaderContent: ({
            filtersStatuses,
            setFiltersStatuses,
            disabled,
            alertOptionTooltips,
            requiredFeature
        }) => (
            <div className="radio-button-wrap flex-row">
                <ImTableHeaderRadioButton
                    option={AlertOption.Daily}
                    imFilterValues={filtersStatuses}
                    onChange={setFiltersStatuses}
                    disabled={disabled}
                    requiredFeature={requiredFeature}
                />
                <EmailInfoTooltip overlayText={alertOptionTooltips[AlertOption.Daily]} />
            </div>
        ),
        renderColumnContent: (filterConfigValue, { disabled, requiredFeature, onColumnClick }) => (
            <ActionBlocker feature={requiredFeature}>
                {blocked =>
                    <RadioButton
                        className="align-top"
                        disabled={disabled || blocked}
                        name={filterConfigValue.name}
                        checked={!blocked && filterConfigValue.alertOption === AlertOption.Daily}
                        onChange={() =>
                            onColumnClick(
                                filterConfigValue.referenceName,
                                AlertOption.Daily
                            )
                        }
                    />}
            </ActionBlocker>
        ),
        className: "",
    }, {
        columnKey: FilterColumnsKeys.Instant,
        renderColumnHeaderContent: ({
            filtersStatuses,
            setFiltersStatuses,
            disabled,
            alertOptionTooltips,
            requiredFeature
        }) => (
            <div className="radio-button-wrap flex-row">
                <ImTableHeaderRadioButton
                    option={AlertOption.Instant}
                    imFilterValues={filtersStatuses}
                    onChange={setFiltersStatuses}
                    disabled={disabled}
                    requiredFeature={requiredFeature}
                />
                <EmailInfoTooltip overlayText={alertOptionTooltips[AlertOption.Instant]} />
            </div>
        ),
        renderColumnContent: (filterConfigValue, { disabled, requiredFeature, onColumnClick }) => (
            <ActionBlocker feature={requiredFeature}>
                {blocked =>
                    <RadioButton
                        className="align-top"
                        disabled={disabled || blocked}
                        name={filterConfigValue.name}
                        checked={!blocked && filterConfigValue.alertOption === AlertOption.Instant}
                        onChange={() =>
                            onColumnClick(
                                filterConfigValue.referenceName,
                                AlertOption.Instant
                            )
                        }
                    />}
            </ActionBlocker>
        ),
        className: "data-list-cell-md-flexible"
    },
];

export const filterColumns = filterColumnDefinitions.map((c) => new ColumnBuilder(c));
