import { AlertOption } from "../../../../../types/email-preferences/EmailPreferences";
import { UserConfigFilter } from "../../../../../types/user-config/UserConfigFilter";
import { Table } from "../../../../bidding/common/table";
import { FilterColumnsKeys, filterColumns } from "./filterColumns";
import { TFilter, TFilterType } from "../../../../../types/filters/FilterState";
import { SavedFilterDetails } from "./SavedFilterDetails";
import { SubscriptionFeature } from "../../../../../types/billing/SubscriptionFeature";
import { textLabels } from "../../../../../constants/text-labels";

interface FilterUpdatesTableProps {
    filtersStatuses: UserConfigFilter[];
    setFiltersStatuses: (updateState: UserConfigFilter[]) => void;
    disabled?: boolean;
    filter: TFilter<TFilterType>;
    filterType: TFilterType;
    visibleFilters: string[];
    alertOptionTooltips?: { [key in AlertOption]?: string };
    requiredFeature?: SubscriptionFeature;
    excludeColumns?: FilterColumnsKeys[];
}

export const defaultAlertOptionToolitps = {
    [AlertOption.Daily]: textLabels.savedFiltersDefaultDailyTooltip,
    [AlertOption.Instant]: textLabels.savedFiltersDefaultInstantTooltip,
}

export const FilterUpdatesTable = ({
    filtersStatuses,
    setFiltersStatuses,
    disabled,
    filter,
    visibleFilters,
    filterType,
    alertOptionTooltips = defaultAlertOptionToolitps,
    requiredFeature,
    excludeColumns = [],
}: FilterUpdatesTableProps) => {
    const onColumnClick = (referenceName: string, alertOption: AlertOption) => {
        const updatedStatus = filtersStatuses.map((v) =>
            v.referenceName === referenceName
                ? {
                    ...v,
                    alertOption,
                }
                : v
        );
        setFiltersStatuses(updatedStatus);
    };

    return (
        <Table
            columns={filterColumns.filter(c => !excludeColumns.includes(c.column.columnKey as FilterColumnsKeys))}
            createHeaderCustomArgs={() => ({
                filtersStatuses,
                setFiltersStatuses,
                disabled,
                alertOptionTooltips,
                requiredFeature
            })}
            createSecurityCustomArgs={() => ({
                onColumnClick,
                disabled,
                requiredFeature
            })}
            collapsible
            className="data-list-striped data-list-saved-filter"
            dataItems={filtersStatuses}
            renderCollapsibleItem={(userFilter: UserConfigFilter) => (
                <SavedFilterDetails
                    userFilter={userFilter}
                    filter={filter}
                    visibleFilters={visibleFilters}
                    filterType={filterType} />
            )}
        />
    );
};
