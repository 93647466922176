import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { errorActions } from "../../../../actions";
import { amrPipelineService } from "../../../../services/amr-pipeline.service";
import { OriginatingTransactionDocument } from "../../../../types/amr-pipeline/models/OriginatingTransactionDocument";
import { OriginatingTransaction } from '../../../../types/amr-pipeline/models/OriginatingTransaction';

export const useIntexFile = (
    transaction: OriginatingTransaction,
    loading: boolean,
    setLoading: (isSending: boolean) => void,
    onLoadSuccess: (file?: OriginatingTransactionDocument) => void
) => {
    const { dealReferenceName, referenceName } = transaction;
    const dispatch = useDispatch();

    useEffect(() => {
        const request = async () => {
            try {
                const intexFile: OriginatingTransactionDocument | undefined = await amrPipelineService.getIntexFile(
                    dealReferenceName,
                    referenceName
                );

                onLoadSuccess(intexFile);
            } catch (error) {
                dispatch(errorActions.criticalError(error));
            } finally {
                setLoading(false);
            }
        };

        if (loading) {
            request();
        }
        // eslint-disable-next-line
    }, [loading]);
};
