import React from 'react';
import { DropDownList, DropDownListItem } from '../../../controls/DropDownList';
import { FilterDropDownListItem, FiltersDropdownItem } from './FiltersDropdownItem';
import { FilterItemAction } from './types';
import { OnHoverTooltip } from '../../../common';

interface FiltersDropDownProps {
    items: DropDownListItem[],
    modified: boolean;
    disabled?: boolean;
    withAlerts?: boolean;
    onChange: (item: DropDownListItem, e: React.MouseEvent) => void;
    onActionClick: (action: FilterItemAction, item: DropDownListItem) => void;
}

export const FiltersDropDown = ({
    items,
    modified,
    onChange,
    onActionClick,
    disabled,
    withAlerts = true,
}: FiltersDropDownProps) => {
    const renderSelectedContent = (item: DropDownListItem) => {
        return (
            <div className="flex-row">
                <OnHoverTooltip overlay={item.text} wrapperClass="text-ellipsis filter-name" overlayClassName="break-word">
                    {item.text}
                </OnHoverTooltip> &nbsp;
                {modified && <div className="default-filter-label">(unsaved)</div>}
            </div>
        )
    };

    const renderItemContent = (item: DropDownListItem, hovered: boolean) => {
        const filterItem = {
            ...item,
            modified: item.selected && modified,
        } as FilterDropDownListItem;

        return (
            <FiltersDropdownItem
                item={filterItem}
                hovered={hovered}
                onActionClick={onActionClick}
                withAlerts={withAlerts}
            />
        );
    };

    return (
        <DropDownList
            placeholder="Select filter"
            disabled={!items.length || disabled}
            items={items}
            onChange={onChange}
            renderSelectedItemCallback={renderSelectedContent}
            renderItemContentCallback={renderItemContent}
            className="custom-drop-down-sm custom-drop-down-filter"
        />
    );
};
