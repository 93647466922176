import React from 'react';
import classnames from 'classnames';
import { OnHoverTooltip } from '../../common';
import { BlockedFeatureTooltipText } from '../../access/BlockedFeatureText';

interface TabProps<T> {
    title: string | React.ReactElement;
    value: T;
    active?: boolean;
    disabled?: boolean;
    onClick?: (value: T) => void;
    className?: string;
}

interface PipelineTabsProps<T> {
    value: T;
    disabled?: boolean;
    onChange: (value: T) => void;
    children: React.ReactElement[];
    className?: string;
}

export const Tab = <T extends string>({ title, value, active, onClick, disabled, className }: TabProps<T>) => (
    <OnHoverTooltip overlay={disabled ? <BlockedFeatureTooltipText /> : null} overlayClassName="trial-tooltip">
        <li className={classnames('tabs-item', className, { active })}>
            <button
                className="btn btn-link tabs-item-link"
                disabled={disabled}
                onClick={() => onClick && onClick(value)}
            >
                {title}
            </button>
        </li>
    </OnHoverTooltip>
);

export const PipelineTabs = <T extends string>({ value, onChange, children, className }: PipelineTabsProps<T>) => (
    <ul className={classnames('tabs', className)}>
        {React.Children.map(children, child =>
            React.cloneElement(child, {
                active: value === child.props.value,
                onClick: (value: T) => onChange(value),
            }),
        )}
    </ul>
);
