import { routes } from "../../constants/routes";
import { AmrTransaction } from "../../types/amr-pipeline/models/AmrTransaction";
import { OriginatingTransaction } from "../../types/amr-pipeline/models/OriginatingTransaction";
import { Breadcrumbs } from "../controls/breadcrumbs/Breadcrumbs";
import { BreadcrumbsItem } from "../controls/breadcrumbs/BreadcrumbsItem";

interface IssuanceMonitorBreadcrumbsProps {
    selectedTransaction: AmrTransaction | OriginatingTransaction;
    isClassActive?: boolean;
}

const IssuanceMonitorBreadcrumbs = ({
    selectedTransaction,
    isClassActive = false,
}: IssuanceMonitorBreadcrumbsProps) => (
    <Breadcrumbs>
        <BreadcrumbsItem route={routes.AMRPipeline} text="Issuance Monitor" />

        {isClassActive && (
            <>
                <BreadcrumbsItem
                    route={routes.transactionDetailUrl(
                        selectedTransaction.referenceName,
                        selectedTransaction.dealReferenceName
                    )}
                    text={selectedTransaction.dealLegalName}
                />
            </>
        )}
    </Breadcrumbs>
);

export default IssuanceMonitorBreadcrumbs;
