import classNames from 'classnames';
import moment from 'moment';
import { constants } from '../../../../constants';
import { IOI } from '../../../../types/amr-pipeline/models/IOI';
import { moneyUtils, formatUtils } from '../../../../utils';
import { ColumnBuilder } from '../../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { IColumnDefinition } from '../../../bidding/common/table/types/ColumnDefinition';
import { OnHoverTooltip } from '../../../common';

const ioiSize: IColumnDefinition<IOI> = {
    columnKey: 'size',
    renderColumnHeaderContent: () => 'IOI Size',
    renderColumnContent: ioi => (ioi.size ? moneyUtils.money(ioi.size) : constants.emptyPlaceholder),
    headerClassName: 'data-list-cell-sm text-right',
    bodyClassName: 'data-list-cell-sm text-right',
};

const ioiSizePercent: IColumnDefinition<IOI> = {
    columnKey: 'sizePercent',
    renderColumnHeaderContent: () => 'IOI Size, %',
    renderColumnContent: ioi =>
        ioi.sizePercent ? formatUtils.formatDecimal(ioi.sizePercent, 2) : constants.emptyPlaceholder,
    headerClassName: 'data-list-cell-sm text-right',
    bodyClassName: 'data-list-cell-sm text-right',
};

const commonColumns: IColumnDefinition<IOI>[] = [
    {
        columnKey: 'dm',
        renderColumnHeaderContent: () => 'DM, bps',
        renderColumnContent: (ioi, { isSeller }) =>
            ioi.dm ? (
                <span className={classNames({ 'refinanced-ioi': !isSeller && ioi.isRefinanced, 'not-refinanced-ioi': !isSeller && !ioi.isRefinanced })}>{ioi.dm}</span>
            ) : (
                constants.emptyPlaceholder
            ),
        headerClassName: 'data-list-cell text-right',
        bodyClassName: 'data-list-cell text-right',
    },
    {
        columnKey: 'oid',
        renderColumnHeaderContent: () => 'OID',
        renderColumnContent: ioi => (ioi.oid ? formatUtils.formatDecimal(ioi.oid, 4) : constants.emptyPlaceholder),
        headerClassName: 'data-list-cell text-right',
        bodyClassName: 'data-list-cell text-right',
    },
    {
        columnKey: 'floaterIndex',
        renderColumnHeaderContent: () => 'Floater Index',
        renderColumnContent: ioi => ioi.floaterIndex,
        headerClassName: 'data-list-cell-sm',
        bodyClassName: 'data-list-cell-sm',
    },
    {
        columnKey: 'margin',
        renderColumnHeaderContent: () => 'Margin, %',
        renderColumnContent: ioi => (ioi.margin ? formatUtils.formatDecimal(ioi.margin) : constants.emptyPlaceholder),
        headerClassName: 'data-list-cell-sm text-right',
        bodyClassName: 'data-list-cell-sm text-right',
    },
    {
        columnKey: 'comment',
        renderColumnHeaderContent: () => 'Comment',
        renderColumnContent: ioi => {
            return (
                <>
                    {ioi.comment ? (
                        <OnHoverTooltip overlay={ioi.comment}>{ioi.comment}</OnHoverTooltip>
                    ) : (
                        constants.emptyPlaceholder
                    )}
                </>
            );
        },
        headerClassName: 'data-list-cell-lg',
        bodyClassName: 'data-list-cell-lg',
    },
    {
        columnKey: 'dateSubmitted',
        renderColumnHeaderContent: () => 'Date Submitted',
        renderColumnContent: ioi =>
            ioi.submitDate ? moment(ioi.submitDate).format(constants.dateTimeFormat) : constants.emptyPlaceholder,
        headerClassName: 'text-right data-list-cell-lg',
        bodyClassName: 'text-right data-list-cell-lg',
    },
];

export const investorColumns = () => {
    const ioiColumnsDefinition: IColumnDefinition<IOI>[] = [ioiSize, ...commonColumns];

    return [[], ioiColumnsDefinition.map(c => {
        return new ColumnBuilder(c);
    }), []]
};

export const arrangerColumns = () => {
    const ioiColumnsDefinition: IColumnDefinition<IOI>[] = [
        {
            columnKey: 'investor',
            renderColumnHeaderContent: () => 'Investor',
            renderColumnContent: ioi => {
                return ioi.companyLegalName ? (
                    <OnHoverTooltip overlay={ioi.companyLegalName}>
                        <span>{ioi.companyLegalName}</span>
                    </OnHoverTooltip>
                ) : (
                    constants.emptyPlaceholder
                );
            },
            headerClassName: 'data-list-cell-xl',
            bodyClassName: 'data-list-cell-xl',
        },
        ioiSize,
        ioiSizePercent,
        ...commonColumns,
    ];

    return [[], ioiColumnsDefinition.map(c => {
        return new ColumnBuilder(c);
    }), []];
};
