import classNames from 'classnames';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { constants } from '../../../../constants';
import { amrPipelineSelector, summarySelector } from '../../../../selectors/amr-pipeline.selector';
import { OnHoverTooltip, Preloader } from '../../../common';
import { SummaryItem } from '../../types/SummaryItem';
import PipelineContext from '../PipelineContext';
import { getClassesSummaryItems, getDealsSummaryItems } from './summary-items';

const dealsSummaryTooltip = 'Deal stats are unavailable when the table is not filtered';
const classesSummaryTooltip = 'Class stats are unavailable when the table is not filtered by particular rating';

export default function Summary() {
    const { pipelineType } = useContext(PipelineContext);

    const {
        filter: { lastAppliedFilter },
    } = useSelector(amrPipelineSelector(pipelineType));
    const {shouldShowTransactionsSummary, shouldShowClassesSummary, transactionsStatistic, classesStatistic, isSummaryRequesting } = useSelector(summarySelector(pipelineType));

    if (isSummaryRequesting) {
        return (
            <div className="sub-header sub-header-pipeline sub-header-summary">
                <div className="sub-header-row sub-header-summary sub-header-summary-loading type02">
                    <Preloader inProgress={true} fullScreen={false} small text="Loading summary..." />
                </div>
            </div>
        );
    }

    const dealsSummaryItems = getDealsSummaryItems(transactionsStatistic!, lastAppliedFilter);
    const classesSummaryItems = getClassesSummaryItems(classesStatistic);

    const renderSummaryGroup = (groupName: string, summaryItems: SummaryItem[], shouldShow: boolean, itemTooltipText?: string) => (
        <div className="summary-box">
            <b className="summary-group-name text-sm">{groupName}:</b>
            {summaryItems.map(i => renderSummaryItem(i, shouldShow, itemTooltipText))}
        </div>
    );

    const renderSummaryItem = (i: SummaryItem, shouldShow: boolean, tooltipText?: string) => {
        let value = i.value || constants.emptyPlaceholder;
        const disabled = !shouldShow && !i.required;
        const medianText = (i.isMedian && `(Median)`) || ``;

        if (disabled) {
            value = constants.emptyPlaceholder;
        }

        return (
            <span key={i.label} className={classNames('summary-element text-sm', { disabled })}>
                <OnHoverTooltip overlay={disabled ? tooltipText :  `${i.label}  ${medianText} ${value}` }>
                    <span className="summary-label">{i.label}</span>
                    <span className="summary-value">{value}</span>
                </OnHoverTooltip>
            </span>
        );
    };

    return (
        <div className="sub-header sub-header-pipeline sub-header-summary">
            <div className="sub-header-row sub-header-summary type02">
                {renderSummaryGroup(
                    'Deal',
                    dealsSummaryItems,
                    shouldShowTransactionsSummary,
                    dealsSummaryTooltip,
                )}
                <span className="vertical-divider" />
                {renderSummaryGroup(
                    'Class',
                    classesSummaryItems,
                    shouldShowClassesSummary,
                    classesSummaryTooltip,
                )}
            </div>
        </div>
    );
}
