import { useState, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { UserConfigFilter } from '../../../../types/user-config/UserConfigFilter';
import { Confirm } from '../../../alerts';
import { DropDownListItem } from '../../../controls/DropDownList';
import { FiltersDropDown } from './FiltersDropdown';
import { FilterItemAction } from './types';
import { createFilterActions } from '../../../../actions/filter.actions';
import { TFilterType } from '../../../../types/filters/FilterState';
import { FilterAlertsPopup } from './FilterAlertsPopup';
import { FilterDropDownListItem } from './FiltersDropdownItem';
import { AlertOption } from '../../../../types/email-preferences/EmailPreferences';
import { ActionBlocker } from '../../../access/ActionBlocker';
import { SubscriptionFeature } from '../../../../types/billing/SubscriptionFeature';

interface Props {
    onChange: () => void;
    disabled?: boolean;
    selectedFilterReferenceName?: string;
    filterModified: boolean;
    filterChanged: boolean;
    filterType: TFilterType;
    filters: UserConfigFilter[],
    onSaveFilterAlert: (referenceName: string, alertOption: AlertOption, isDefault: boolean) => void;
    onDeleteFilter: (referenceName: string) => void;
    withBlockedSearchAndFilter?: boolean;
    onMakeDefault: (referenceName: string, isDefault: boolean) => void;
    onResetDefault: (referenecName: string, isDefault: boolean) => void;
    withAlerts?: boolean;
    filterAlertPopupDescription?: string;
    alertOptionInfo?: { [key in AlertOption]?: string };
    blockerFeature?: SubscriptionFeature;
    renderFilterAlertsPopup?: (filter: UserConfigFilter, onClose: () => void) => React.ReactNode;
    alertOptions?: AlertOption[];
}

export const FiltersManagement = ({
    onChange,
    disabled,
    filterModified,
    filterChanged,
    selectedFilterReferenceName,
    filters,
    onMakeDefault,
    onResetDefault,
    onSaveFilterAlert,
    onDeleteFilter,
    filterType,
    withBlockedSearchAndFilter = false,
    withAlerts = true,
    filterAlertPopupDescription = "Receive an email when transactions are published or updated on Issuance Monitor.",
    alertOptionInfo,
    blockerFeature,
    renderFilterAlertsPopup,
    alertOptions
}: Props) => {
    const filterActions = createFilterActions(filterType);

    const dispatch = useDispatch();

    const mapFilterConfig = (userConfigFilter: UserConfigFilter) => {
        return {
            value: userConfigFilter.referenceName,
            text: userConfigFilter.name,
            selected: userConfigFilter.referenceName === selectedFilterReferenceName,
            payload: userConfigFilter,
        } as DropDownListItem;
    };

    const [listItemForDeletion, setListItemForDeletion] = useState<DropDownListItem>();
    const [filterForAlert, setFilterForAlert] = useState<DropDownListItem>();

    useEffect(() => {
        if (filterModified && !filterChanged) {
            dispatch(filterActions.setFilterByReferenceName('', undefined));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterModified, filterChanged]);

    const handleFilterChange = (item: DropDownListItem) => {
        const referenceName = item.value as string;

        const selectedFilter = filters.find(filter =>
            referenceName ? filter.referenceName === referenceName : filter.default,
        );

        dispatch(filterActions.selectFilterFromConfig(selectedFilter, referenceName));
        onChange();
    };

    const handleFilterItemAction = (action: FilterItemAction, item: DropDownListItem) => {
        switch (action) {
            case FilterItemAction.MakeDefault:
                onMakeDefault(item.value as string, true);
                break;
            case FilterItemAction.ResetDefault:
                onResetDefault(item.value as string, false);
                break;
            case FilterItemAction.DiscardChanges:
                break;
            case FilterItemAction.SetFilterForPopup:
                setFilterForAlert(item as FilterDropDownListItem);
                break;
            case FilterItemAction.Delete:
                setListItemForDeletion(item);
                break;
        }
    };

    const handleDeleteFilter = () => {
        listItemForDeletion && onDeleteFilter(listItemForDeletion.payload.referenceName);
        listItemForDeletion && setListItemForDeletion(undefined);
    };

    const filterConfig = useMemo(
        () => (filters ? filters.map(mapFilterConfig) : []),
        [filters, selectedFilterReferenceName], // eslint-disable-line react-hooks/exhaustive-deps
    );

    const handleConfirmFilterAlert = (alertOption: AlertOption) => {
        onSaveFilterAlert(filterForAlert!.value as string, alertOption, filterForAlert!.payload.default);
    };

    return (
        <div className="saved-filters-wrap">
            <ActionBlocker feature={blockerFeature}>
                {blocked =>
                    <div className="flex-row">
                        <span className={cn("saved-filters-label", { "text-light-grey pointer-events-none": disabled || withBlockedSearchAndFilter || blocked })}>Saved filters:</span>
                        <FiltersDropDown
                            disabled={disabled || withBlockedSearchAndFilter || blocked}
                            modified={filterModified}
                            items={filterConfig}
                            onChange={handleFilterChange}
                            onActionClick={handleFilterItemAction}
                            withAlerts={withAlerts}
                        />
                    </div>
                }
            </ActionBlocker>
            {filterForAlert && (renderFilterAlertsPopup?.(filterForAlert.payload, () => setFilterForAlert(undefined)) ??
                <FilterAlertsPopup
                    description={filterAlertPopupDescription}
                    alertOptionInfo={alertOptionInfo}
                    alertOptions={alertOptions}
                    onClose={() => setFilterForAlert(undefined)}
                    onConfirm={handleConfirmFilterAlert}
                    filter={filterForAlert}
                />
            )}
            {listItemForDeletion && (
                <Confirm
                    title="Confirmation"
                    text={`Do you really want to delete ${listItemForDeletion.payload.name} filter?`}
                    onConfirm={handleDeleteFilter}
                    onCancel={() => setListItemForDeletion(undefined)}
                />
            )}
        </div>
    );
};
