import { roles, routes } from "../../../../constants"
import { SubscriptionFeature } from "../../../../types/billing/SubscriptionFeature"
import { queryStringSerializer } from "../../../../utils/filtering"
import { QueryStringArgumentSerializer } from "../../../../utils/filtering/serializers/QueryStringArgumentSerializer"
import { dealsLegalNames as dealLegalNamesSerializer, imPipelineTab } from "../../../../utils/filtering/serializers/serializer.definitions"
import { FeatureLink } from "../../../access/FeatureLink"
import { RoleActionBlocker } from "../../../access/RoleActionBlocker"
import { ValueBlocker } from "../../../access/ValueBlocker"
import { OnHoverTooltip } from "../../../common"
import { IssuanceMonitorTab } from "../../types/PipelineTabTypes"

interface Props {
    dealsLegalNames?: string[]
    requiredFeatures?: SubscriptionFeature[]
    tooltip?: string
    children?: React.ReactNode;
}
export function ArrangerPipelineLink({
    dealsLegalNames = [],
    requiredFeatures = [SubscriptionFeature.IssuanceMonitorFullAccess],
    tooltip,
    children
}: Props) {

    const createUrl = () => {
        const searchParams = {
            dealsLegalNames,
            imPipelineTab: IssuanceMonitorTab.ArrangerPipeline,
        };

        const serializer: QueryStringArgumentSerializer<any>[] = [
            dealLegalNamesSerializer(),
            imPipelineTab(),
        ];

        const qs = queryStringSerializer.serialize(searchParams, serializer);
        return routes.AMRPipeline + '?' + qs;
    }

    return (
        <RoleActionBlocker roles={roles.getAllRolesExclude(roles.Viewer)}>
            {
                roleBlocked =>
                    <ValueBlocker features={requiredFeatures} overrideRequiredFeatures={roleBlocked}>
                        <OnHoverTooltip overlay={tooltip}>
                            <FeatureLink
                                className="btn-link secondary regular"
                                path={createUrl()}
                                target="_blank"
                            >
                                {children}
                            </FeatureLink>
                        </OnHoverTooltip>
                    </ValueBlocker>
            }
        </RoleActionBlocker>
    )
}