import React from "react";
import { OriginatingTransactionClass } from "../../../../types/amr-pipeline/models/OriginatingTransactionClass";
import { AmrClass } from "../../../../types/amr-pipeline/models/AmrClass";
import { classesColumns } from './columns/classesColumns';
import { PipelineList } from "../PipelineList";
import { PipelineView } from "../../types/PipelineView";
import { UserConfigColumn } from "../../../../types/user-config/UserConfigColumn";
import { PipelineEmptyPlaceholder } from '../PipelineEmptyPlaceholder';

interface Props {
    columnsConfig: UserConfigColumn[];
    classes: (OriginatingTransactionClass | AmrClass)[];
    onRedirect: () => void;
}

export const ClassView = ({ columnsConfig, classes, onRedirect }: Props) => {
    return classes.length
        ? <PipelineList className={'pipeline-list-class'} dataItems={classes} columns={classesColumns(columnsConfig)} view={PipelineView.Class} />
        : <PipelineEmptyPlaceholder onRedirect={onRedirect} />
}
