import { AlertOption } from "../../../../../types/email-preferences/EmailPreferences";
import {
    StatisticsAlertOption,
} from "../../../../../types/amr-pipeline/enums/AlertOption";
import { UserConfigFilter } from "../../../../../types/user-config/UserConfigFilter";
import { RadioButton } from "../../../../controls";
import EmailInfoTooltip from "../../../../common/EmailInfoTooltip";
import { alertOptionTooltipText, alertOptions, statisticsAlertOptions } from "../../../../../constants/amr-pipeline/alertOptions";
import { FilterUpdatesTable } from "./FilterUpdatesTable";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { pipelineFilters, amrArrangerPipelineFilters } from "../../../../../constants";
import { issuanceMonitorFilterSelector } from "../../../../../selectors/amr-pipeline.selector";
import { PipelineType } from "../../../../../types/amr-pipeline/enums/PipelineType";
import { UserConfigType } from "../../../../../types/user-config/UserConfigType";
import PipelineContext from "../../PipelineContext";
import { SubscriptionFeature } from "../../../../../types/billing/SubscriptionFeature";
import { ActionBlocker } from "../../../../access/ActionBlocker";
import { RequiredFeature } from "../../../../access/RequiredFeature";
import { BlockedFeatureContent, SubscribeLink } from "../../../../../components/access/BlockedFeatureText";

interface Props {
    disabled?: boolean;
    singleUpdate?: AlertOption;
    onChangeSingleUpdate: (option: AlertOption) => void;
    newTransactionUpdate?: AlertOption;
    onChangeNewTransactionUpdate: (option: AlertOption) => void;
    weeklyStatistic?: StatisticsAlertOption;
    onChangeWeeklyStatistic: (option: StatisticsAlertOption) => void;
    filterAlerts?: UserConfigFilter[];
    onChangeFilterAlerts: (options: UserConfigFilter[]) => void;
}

export const ImEmailAlerts = ({
    disabled,
    singleUpdate = AlertOption.Never,
    onChangeSingleUpdate,
    newTransactionUpdate = AlertOption.Never,
    onChangeNewTransactionUpdate,
    weeklyStatistic = StatisticsAlertOption.Never,
    onChangeWeeklyStatistic,
    filterAlerts = [],
    onChangeFilterAlerts,
}: Props) => {
    const withAlertsFilters = !!filterAlerts.length;

    const { pipelineType } = useContext(PipelineContext);


     const filterConfigType =
         pipelineType === PipelineType.ArrangerPipeline ? UserConfigType.apFilter : UserConfigType.imFilter;

     const visibleFilters =
         filterConfigType === UserConfigType.imFilter
             ? pipelineFilters.visibleFilters
             : amrArrangerPipelineFilters.visibleFilters;

     const { filter } = useSelector(issuanceMonitorFilterSelector(pipelineType));


    const renderInfoBlock = (withFilters: boolean) => (
        <p className="controls-wrap-info">
            {withFilters
                ? "Receive an email when new transactions matching your saved filter are published or updated on Issuance Monitor."
                : "There are no saved filters yet to receive an email notifications."}
        </p>
    );

    const renderUpdatesTable = () => {
        if(withAlertsFilters) {
            return (
                <>
                    {renderInfoBlock(withAlertsFilters)}
                    <FilterUpdatesTable
                        disabled={disabled}
                        filter={filter}
                        visibleFilters={visibleFilters}
                        filterType={pipelineType}
                        filtersStatuses={filterAlerts}
                        setFiltersStatuses={onChangeFilterAlerts}
                        requiredFeature={SubscriptionFeature.IssuanceMonitorFullAccess}
                        alertOptionTooltips={{
                            [AlertOption.Instant]: alertOptionTooltipText[AlertOption.Instant],
                            [AlertOption.Daily]: alertOptionTooltipText[AlertOption.Daily],
                        }}
                    />
                </>
            );
        }

        return (
            <RequiredFeature
                feature={SubscriptionFeature.IssuanceMonitorFullAccess}
                renderBlockedContent={() => (
                    <>
                        {renderInfoBlock(true)}
                        <BlockedFeatureContent
                            inline
                            className="empty-placeholder empty-placeholder-saved-filters"
                            text={<><SubscribeLink /> to unlock the saved filters.</>}
                        />
                    </>
                )}
            >
                {renderInfoBlock(false)}
            </RequiredFeature>
        );
    }

    return (
        <div>
            <div className="controls-wrap">
                <div className="controls-wrap-head">
                    <h4 className="controls-wrap-title">
                        Every Single Update
                    </h4>
                    <div className="controls-wrap-radio">
                        {alertOptions.map((alertOption) => (
                            <div
                                key={alertOption}
                                className="controls-radio-item"
                            >
                                <ActionBlocker feature={SubscriptionFeature.IssuanceMonitorFullAccess}>
                                    {(blocked) => (
                                        <RadioButton
                                            disabled={blocked || disabled}
                                            name={`single-update-${alertOption}`}
                                            label={alertOption}
                                            checked={
                                                blocked
                                                    ? alertOption ===  AlertOption.Never
                                                    : alertOption === singleUpdate
                                            }
                                            onChange={() =>
                                                onChangeSingleUpdate(alertOption)
                                            }
                                        />
                                    )}
                                </ActionBlocker>
                                {alertOption !== AlertOption.Never && (
                                    <EmailInfoTooltip overlayText={alertOptionTooltipText[alertOption as keyof typeof alertOptionTooltipText]} />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                <p className="controls-wrap-info">
                    Receive an email when transactions are published or updated
                    on Issuance Monitor.
                </p>
            </div>
            <div className="controls-wrap">
                <div className="controls-wrap-head">
                    <h4 className="controls-wrap-title">
                        New Transactions
                    </h4>
                    <div className="controls-wrap-radio">
                        {alertOptions.map((alertOption) => (
                            <div
                                key={alertOption}
                                className="controls-radio-item"
                            >
                                <ActionBlocker feature={SubscriptionFeature.IssuanceMonitorFullAccess}>
                                    {(blocked) => (
                                        <RadioButton
                                            disabled={disabled || blocked}
                                            name={`new-transactions-${alertOption}`}
                                            label={alertOption}
                                            checked={
                                                blocked
                                                    ? alertOption ===  AlertOption.Never
                                                    : alertOption === newTransactionUpdate
                                            }
                                            onChange={() =>
                                                onChangeNewTransactionUpdate(
                                                    alertOption
                                                )
                                            }
                                        />
                                    )}
                                </ActionBlocker>
                                {alertOption !== AlertOption.Never && (
                                    <EmailInfoTooltip
                                        overlayText={
                                            alertOption === AlertOption.Instant
                                                ? "Alerts are sent once transactions are published"
                                                : alertOptionTooltipText[alertOption as keyof typeof alertOptionTooltipText]
                                        }
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                <p className="controls-wrap-info">
                    Receive an email when new transactions are published on
                    Issuance Monitor.
                </p>
            </div>
            <div className="controls-wrap">
                <div className="controls-wrap-head">
                    <h4 className="controls-wrap-title">
                        Issuance Weekly Summary
                    </h4>
                    <div className="controls-wrap-radio">
                        {statisticsAlertOptions.map((option) => (
                            <div key={option} className="controls-radio-item">
                                <ActionBlocker feature={SubscriptionFeature.IssuanceMonitorFullAccess}>
                                    {(blocked) => (
                                        <RadioButton
                                            disabled={disabled || blocked}
                                            name={`receive-statistic-${option}`}
                                            label={option}
                                            checked={
                                                blocked
                                                    ? option ===  StatisticsAlertOption.Never
                                                    : option === weeklyStatistic
                                            }
                                            onChange={() =>
                                                onChangeWeeklyStatistic(option)
                                            }
                                        />
                                    )}
                                </ActionBlocker>
                                {option === StatisticsAlertOption.Weekly && (
                                    <EmailInfoTooltip overlayText={alertOptionTooltipText[option]} />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                <p className="controls-wrap-info">Receive an email notification with CLO primary market highlights.</p>
            </div>
            <div className="controls-wrap-head">
                <h4 className="controls-wrap-title">Saved Filters</h4>
            </div>
            {renderUpdatesTable()}
        </div>
    );
};
