import moment from "moment";
import { FilterDateOption } from "../../../../../types/filters/FilterDateOption";
import { FilterDateTimeOption } from "../../../../../types/filters/FilterDateTimeOption";
import { FilterGroup, FilterType } from "../../../../../types/filters/FilterGroup";
import { FilterOption } from "../../../../../types/filters/FilterOption";
import { UserConfigFilter } from "../../../../../types/user-config/UserConfigFilter";
import { deserializeFilters } from "../../../../../utils/filtering/serializers/amr-pipeline/deserializeFilters";
import { FilterDateRangeTitle } from "../../../../common/filters/FilterDateRange";
import { FilterRangeOption } from "../../../../../types/filters/FilterRangeOption";
import { formatUtils } from "../../../../../utils/format.utils";
import { CollateralType } from "../../../../../types/amr-pipeline/enums/CollateralType";
import { FilterRadioOption } from "../../../../../types/filters/FilterRadioOption";
import { compareStrings } from "../../../../../utils/compare.utils";
import { FilterBooleanOption } from "../../../../../types/filters/FilterBooleanOption";
import { TFilter, TFilterType } from "../../../../../types/filters/FilterState";

const dateTimeFormat = "MM/DD/YYYY, hh:mm A";

const renderFilterValue = (filterData: FilterGroup) => {
    const { type, filter } = filterData;

    switch (type) {
        case FilterType.Select:
            return (filter as FilterOption[])
                ?.filter((f) => f.selected)
                .map((f) => <span key={f.value} className="name-tag">{f.text}</span>);
        case FilterType.Radio: {
            const radioFilter = (filter as FilterRadioOption<CollateralType>);
            const selectedRadio = radioFilter.options.find(o => o.value === radioFilter.selectedOption);
            return <span className="name-tag">{selectedRadio?.text || radioFilter.selectedOption}</span>;
        }
        case FilterType.BooleanRadio:
            return (
                <span className="name-tag text-capitalize">{formatUtils.formatBoolean((filter as FilterBooleanOption).selectedOption)}</span>
            );
        case FilterType.Date:
        case FilterType.DateWithYearsRange:
            return (
                <span className="name-tag">
                    <FilterDateRangeTitle
                        selected={(filter as FilterDateOption).selectedOption}
                        customDateRange={(filter as FilterDateOption).options.customDateRange}
                        customYearsRange={(filter as FilterDateOption).options.customYearsRange}
                    />
                </span>
            );
        case FilterType.DateWithYearsAndTimeRange: {
            const fromDate = (filter as FilterDateTimeOption).selectedOption?.from?.date;
            const toDate = (filter as FilterDateTimeOption).selectedOption?.to?.date;
            return (
                <span className="name-tag">
                    {(filter as FilterDateTimeOption).selectedOption?.formatTitle
                        ? (filter as FilterDateTimeOption).selectedOption?.formatTitle(String(fromDate), String(toDate))
                        : `${moment(fromDate).format(dateTimeFormat)} - ${moment(toDate).format(dateTimeFormat)}`}
                </span>
            );
        }
        case FilterType.YearsDate: {
            const { from, to } = (filter as FilterDateOption).options.customDateRange;
            const selectedOption = (filter as FilterDateOption).selectedOption;

            const yearFrom = moment(from).year();
            const yearTo = moment(to).year();

            const title = yearFrom === yearTo ? yearFrom : `${yearFrom} - ${yearTo}`;
            const formattedTitle = selectedOption?.formatTitle ? selectedOption.formatTitle(String(title)) : title;

            return (
                <span className="name-tag">
                    {selectedOption?.selectedTitle ?? formattedTitle}
                </span>
            )
        }
        case FilterType.Range:
            const { from, to } = filter as FilterRangeOption;

            return (
                <span className="name-tag">
                    {`${formatUtils.formatDecimal(
                        from as number
                    )} - ${formatUtils.formatDecimal(to as number)}`}
                </span>
            );
    }
};

interface SavedFilterDetailsProps {
    userFilter: UserConfigFilter;
    filter: TFilter<TFilterType>;
    filterType: TFilterType;
    visibleFilters: string[]
}

export const SavedFilterDetails = ({ userFilter, filter, filterType, visibleFilters }: SavedFilterDetailsProps) => {
    const deserializedFilters = deserializeFilters(
        userFilter,
        filter,
        filterType
    );

    const alphabeticalFilterNameList = Object.keys(deserializedFilters)
        .filter((key) => !visibleFilters.includes(key))
        .sort((a, b) => compareStrings(filter[a]?.text, filter[b]?.text));

    const userFilterData = [
        ...visibleFilters,
        ...alphabeticalFilterNameList,
    ].reduce((accum: FilterGroup[], key) => {
        const filterData =
            deserializedFilters[key as keyof typeof deserializedFilters];
        if (filterData) {
            return [
                ...accum,
                {
                    ...filter[key],
                    filter: filterData,
                },
            ];
        }
        return accum;
    }, []);

    return (
        <div className="saved-filters-options">
            {userFilterData.map((filterData) => {
                const { key, text } = filterData;

                return (
                    <div key={key} className="saved-filter-options">
                        <span className="saved-filter-options-title text-warm-grey">{text}</span>
                        <div className="saved-filter-options-list">
                            {renderFilterValue(filterData)}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
