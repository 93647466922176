import { useState, useEffect, useMemo } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import {
    amrPipelineCommonActions,
} from '../../../actions/';
import { Pipeline } from './Pipeline';
import { PipelineDashboard } from './PipelineDashboard';
import { PipelineView } from '../types/PipelineView';
import { useSelector } from 'react-redux';
import PipelineContext from './PipelineContext';
import { PipelineType } from '../../../types/amr-pipeline/enums/PipelineType';
import { ArrangerPipeline } from './arranger-pipeline/ArrangerPipeline';
import { IssuanceMonitorTab } from '../types/PipelineTabTypes';
import { ArrangerPipelineEdit } from './arranger-pipeline/ArrangerPipelineEdit';
import { user } from '../../../user';
import { roles } from '../../../constants';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { amrDealsSelector, arrangerPipelineDealsSelector } from '../../../selectors/amr-pipeline.selector';
import { IssuanceMonitorLayout } from './IssuanceMonitorLayout';
import { useAppDispatch } from '../../../effects/useAppDispatch';

export const IssuanceMonitor = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();

    const { imPipelineTab, pipelineViewMode } = queryString.parse(location.search, { decode: false });

    const anyPipelineExists = useSelector(arrangerPipelineDealsSelector).anyPipelineExists;
    const anyIOIsExists = useSelector(amrDealsSelector(PipelineType.IOIs)).anyIOIsExists;

    const withImSubscription = user.hasFeatures(SubscriptionFeature.IssuanceMonitorFullAccess);

    const [viewMode, setViewMode] = useState(pipelineViewMode as PipelineView || PipelineView.Deal);
    const [tab, setTab] = useState(imPipelineTab as IssuanceMonitorTab || IssuanceMonitorTab.Deals);

    const [isOptionsColumnCollapsed, setIsOptionsColumnCollapsed] = useState({
        [IssuanceMonitorTab.Deals]: false,
        [IssuanceMonitorTab.IOIs]: false
    });

    const [filtersCollapseStatus, setFiltersCollapseStatus] = useState({
        [PipelineType.Deals]: false,
        [PipelineType.IOIs]: false,
        [PipelineType.ArrangerPipeline]: false
    });

    const contextValue = useMemo(() => {
        const collapseState = {
            filtersCollapseStatus,
            setFiltersCollapseStatus: (isCollapsed: boolean) => {
                setFiltersCollapseStatus(prevValue => ({
                    ...prevValue,
                    [tab]: isCollapsed,
                }));
            },
        };

        const optionCollapseStatus = {
            optionColumnCollapseStatus: isOptionsColumnCollapsed,
            setOptionColumnCollapseStatus: (isCollapsed: boolean) => {
                setIsOptionsColumnCollapsed(prevValue => ({
                    ...prevValue,
                    [tab]: isCollapsed
                }))
            }
        };

        switch (tab) {
            case IssuanceMonitorTab.IOIs:
                return {
                    pipelineType: PipelineType.IOIs,
                    setTab: (tab: IssuanceMonitorTab) => setTab(tab),
                    ...collapseState,
                    ...optionCollapseStatus,
                    withImSubscription,
                    withBlockedSearchAndFilter: !anyIOIsExists,
                };
            case IssuanceMonitorTab.ArrangerPipeline:
            case IssuanceMonitorTab.ArrangerPipelineEdit:
                return {
                    pipelineType: PipelineType.ArrangerPipeline,
                    setTab: (tab: IssuanceMonitorTab) => setTab(tab),
                    ...collapseState,
                    ...optionCollapseStatus,
                    withImSubscription,
                    withBlockedSearchAndFilter: !anyPipelineExists,
                };
            default:
                return {
                    pipelineType: PipelineType.Deals,
                    setTab: (tab: IssuanceMonitorTab) => setTab(tab),
                    ...collapseState,
                    ...optionCollapseStatus,
                    withImSubscription,
                };
        }
    }, [tab, filtersCollapseStatus, isOptionsColumnCollapsed, withImSubscription, anyPipelineExists, anyIOIsExists]);

    useEffect(() => {
        if (user.hasSingleRole(roles.ArrangersClient)) {
            dispatch(amrPipelineCommonActions.redirectInvitedClientToTransactionDetails())
            return;
        }
    }, [dispatch]);

    const renderPipeline = (tab: IssuanceMonitorTab) => {
        switch (tab) {
            case IssuanceMonitorTab.Dashboard:
                return <PipelineDashboard onWidgetItemClick={() => setTab(IssuanceMonitorTab.Deals)} />;
            case IssuanceMonitorTab.ArrangerPipeline:
                return <ArrangerPipeline />;
            case IssuanceMonitorTab.Deals:
            case IssuanceMonitorTab.IOIs:
                return <Pipeline viewMode={viewMode} onRedirect={() => setTab(IssuanceMonitorTab.Deals)} />;
            default:
                return null;
        }
    };

    const renderIssuanceMonitor = () => {
        if (tab === IssuanceMonitorTab.ArrangerPipelineEdit) {
            return <ArrangerPipelineEdit />;
        }

        return (
            <IssuanceMonitorLayout tab={tab} setTab={setTab} viewMode={viewMode} setViewMode={setViewMode}>
                {renderPipeline(tab)}
            </IssuanceMonitorLayout>
        )
    };

    return (
        <PipelineContext.Provider value={contextValue}>
            <div className="container container-pipeline">
                {renderIssuanceMonitor()}
            </div>
        </PipelineContext.Provider>
    );
};
