import moment from "moment";
import { capitalize, isNil, startCase } from "lodash";
import { IColumnDefinition } from "../../../../bidding/common/table/types/ColumnDefinition";
import { ColumnBuilder } from "../../../../bidding/common/table/columns/column-builder/ColumnBuilder";
import { constants, routes, pipelineColumnLabels, pipelineTooltipText, roles } from "../../../../../constants";
import {
    dateTimeUtils,
    formatUtils,
    moneyUtils,
    numericUtils,
    amrFormatUtils,
} from "../../../../../utils";
import StatusLabel from "../../../StatusLabel";
import { OriginatingTransactionClass } from "../../../../../types/amr-pipeline/models/OriginatingTransactionClass";
import { AmrClass } from "../../../../../types/amr-pipeline/models/AmrClass";
import { TransactionType } from "../../../../../types/amr-pipeline/enums/TransactionType";
import { NonAmrPeriod } from "../../../common/NonAmrPeriod";
import { canViewTransactionDetails, getTransactionDetailsUrl, hasLimitedAccess } from "../../../../../utils/amr-pipeline.utils";
import { OnHoverTooltip } from "../../../../common";
import { OverlayClassNameContent } from "../../../common/OverlayClassNameContent";
import { PipelineColumn } from '../../../types/PipelineColumn';
import { UserConfigColumn } from '../../../../../types/user-config/UserConfigColumn';
import { transactionStatusTitles } from "../../../../../types/amr-pipeline/enums/TransactionStatus";
import { constants as amrConstants } from "../../../common/constants";
import { Popover } from "../../../../controls";
import { NewDebutAmrLabel } from "../../../common/NewDebutAmrLabel";
import { MarkdownViewer, renderMarkdownOrEmpty } from '../../../../common/markdown-editor';
import IconSVG from "../../../../../styles/svg-icons";
import { Link } from "react-router-dom";
import { OriginatingTransactionClassStatus, transactionClassStatusTitles } from "../../../../../types/amr-pipeline/enums/OriginatingTransactionClassStatus";
import { LastUpdatedPopover } from '../../LastUpdatedPopover';
import { DealUpdatesPopover } from "../../DealUpdatesPopover";
import { optionsButtonColumnDefinition, optionsColumnDefinition, shareOptionColumnDefinition } from "./optionsColumn";
import { collapseStickRightColumn } from "../../../../bidding/common/table/CollapseStickRightColumn";
import { user } from "../../../../../user";
import { ImSubscriptionActionBlocker } from "../../../subscription/ImSubscriptionActionBlocker";
import { ManagerLink } from '../../../common/ManagerLink';
import { OriginatingTransaction } from '../../../../../types/amr-pipeline/models/OriginatingTransaction';
import { ImSubscriptionValue } from '../../../subscription/ImSubscriptionValue';
import { BankLink } from "../../../common/BankLink";

export const classesColumns = (columnsConfig: UserConfigColumn[]) => {
    const fixedColumnDefinitions: IColumnDefinition<
        OriginatingTransactionClass | AmrClass
    >[] = [
            {
                columnKey: PipelineColumn.isNew,
                renderColumnHeaderContent: () => "",
                renderColumnContent: (transactionClass: AmrClass | OriginatingTransactionClass) =>
                    <NewDebutAmrLabel transaction={transactionClass.transaction} />,
                stickLeft: true,
                className: "data-list-cell-xxs-02",
            }
        ];

    const classesColumnDefinitions: IColumnDefinition<
        OriginatingTransactionClass | AmrClass
    >[] = [
            {
                columnKey: PipelineColumn.lastUpdated,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.lastUpdated],
                renderColumnContent: (transactionClass) => (
                    <ImSubscriptionActionBlocker>
                        {blocked => {
                            const {
                                referenceName,
                                dealReferenceName,
                                dealLegalName,
                                lastUpdated,
                                type,
                                status,
                                version,
                            } = transactionClass.transaction;

                            const dealUpdates = (transactionClass.transaction as OriginatingTransaction).dealUpdates;

                            return (
                                <DealUpdatesPopover
                                    disabled={blocked}
                                    referenceName={referenceName}
                                    dealReferenceName={dealReferenceName}
                                    dealLegalName={dealLegalName}
                                    lastUpdated={lastUpdated}
                                    type={type}
                                    status={status}
                                    version={version}
                                    dealUpdates={dealUpdates}
                                />
                            );
                        }}
                    </ImSubscriptionActionBlocker>
                ),
                sortingField: PipelineColumn.lastUpdated,
                stickLeft: true,
                className: "data-list-cell-sm",
            },
            {
                columnKey: PipelineColumn.dealName,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.dealName],
                renderColumnContent: (transactionClass) => {
                    const { transaction, dealLegalName } = transactionClass;

                    return (
                        <div className="name-with-link">
                            <div className="text-ellipsis name-box">
                                <OnHoverTooltip overlay={dealLegalName}>
                                    {(transaction.type === TransactionType.amr || canViewTransactionDetails()) ? (
                                        <Link
                                            target="_blank"
                                            to={getTransactionDetailsUrl(transaction)}
                                            className="link-filter"
                                        >
                                            {dealLegalName}
                                        </Link>
                                    ) : dealLegalName}
                                </OnHoverTooltip>
                            </div>
                        </div>
                    );
                },
                sortingField: PipelineColumn.dealName,
                className: "data-list-cell-xl-xxl",
                stickLeft: true,
            },
            {
                columnKey: PipelineColumn.className,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.className],
                renderColumnContent: (transactionClass) => {
                    const isNotOffered =
                        (transactionClass as OriginatingTransactionClass)
                            .originatingTransactionClassStatus ===
                        OriginatingTransactionClassStatus.NotOffered;

                    return (
                        <OnHoverTooltip
                            overlay={
                                <OverlayClassNameContent
                                    transactionClass={transactionClass as OriginatingTransactionClass}
                                />
                            }
                        >
                            {transactionClass.transaction.type ===
                                TransactionType.amr ? (
                                <Link
                                    target="_blank"
                                    className="text-ellipsis"
                                    to={routes.transactionClassUrl(
                                        transactionClass.transactionReferenceName,
                                        transactionClass.dealReferenceName,
                                        transactionClass.referenceName
                                    )}
                                >
                                    {`${transactionClass.name} ${isNotOffered ? amrConstants.notOffered : ""
                                        }`}
                                </Link>
                            ) : (
                                <span className="text-ellipsis">
                                    {`${transactionClass.name} ${isNotOffered ? amrConstants.notOffered : ""
                                        }`}
                                </span>
                            )
                            }
                        </OnHoverTooltip>
                    )
                },
                sortingField: PipelineColumn.className,
                stickLeft: true,
            },
            {
                columnKey: PipelineColumn.manager,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.manager],
                renderColumnContent: (transactionClass) => {
                    const { collateralManager } = transactionClass.transaction

                    if (!collateralManager) {
                        return constants.emptyPlaceholder;
                    }

                    return (
                        <ManagerLink
                            referenceName={collateralManager.referenceName}
                            legalName={collateralManager.legalName}
                        />
                    );
                },
                sortingField: "collateralManager",
                className: "data-list-cell-xl",
            },
            {
                columnKey: PipelineColumn.arranger,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.arranger],
                renderColumnContent: (transactionClass) => {
                    const { arranger } = transactionClass.transaction

                    if (!arranger) {
                        return constants.emptyPlaceholder;
                    }

                    return (
                        <BankLink
                            legalName={arranger.code ?? arranger.legalName}
                            referenceName={arranger.referenceName}
                            overlayText={arranger.legalName}
                        />
                    );
                },
                sortingField: PipelineColumn.arranger,
            },
            {
                columnKey: PipelineColumn.trustee,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.trustee],
                renderColumnContent: ({ transaction }) => transaction.trustee
                    ? <OnHoverTooltip overlay={transaction.trustee?.legalName}>{transaction.trustee?.legalName}</OnHoverTooltip>
                    : constants.emptyPlaceholder,
                headerClassName: "data-list-cell-xl",
                bodyClassName: "data-list-cell-xl",
                sortingField: PipelineColumn.trustee,
            },
            {
                columnKey: PipelineColumn.transactionStatus,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.transactionStatus],
                renderColumnContent: (transactionClass) => (
                    <StatusLabel status={transactionStatusTitles[transactionClass.transaction.status]} />
                ),
                sortingField: "transactionStatus",
                className: "data-list-cell-trans-status",
            },
            {
                columnKey: PipelineColumn.transactionType,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.transactionType],
                renderColumnContent: (transactionClass) =>
                    startCase(transactionClass.transaction.type),
                sortingField: PipelineColumn.transactionType,
            },
            {
                columnKey: PipelineColumn.pricingDate,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.pricingDate],
                renderColumnContent: (transactionClass) => {
                    var amrClass = transactionClass as AmrClass;
                    var pricingDate = amrClass?.pricingDate ?? transactionClass.transaction.pricingDate;
                    return pricingDate
                        ? moment(pricingDate).format(constants.dateFormat)
                        : constants.emptyPlaceholder
                },
                className: "data-list-cell-sm",
                sortingField: PipelineColumn.pricingDate,
            },
            {
                columnKey: PipelineColumn.closingDate,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.closingDate],
                renderColumnContent: (transactionClass) => {
                    var amrClass = transactionClass as AmrClass;
                    var closingDate = amrClass?.settlementDate ?? transactionClass.transaction.closingDate;

                    return closingDate
                        ? moment(closingDate).format(constants.dateFormat)
                        : constants.emptyPlaceholder
                },
                className: "data-list-cell-sm",
                sortingField: PipelineColumn.closingDate,
            },
            {
                columnKey: PipelineColumn.nonCallPeriodEnd,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.nonCallPeriodEnd],
                renderColumnContent: ({ transaction }) =>
                    transaction.nonCallPeriodEnd
                        ? moment(transaction.nonCallPeriodEnd).format(constants.dateFormat)
                        : constants.emptyPlaceholder,
                sortingField: PipelineColumn.nonCallPeriodEnd,
                headerClassName: "data-list-cell-sm",
                bodyClassName: "data-list-cell-sm popover-column",
            },
            {
                columnKey: PipelineColumn.nonCallPeriodEndDays,
                renderColumnHeaderContent: () =>
                    <OnHoverTooltip overlay={pipelineTooltipText[PipelineColumn.nonCallPeriodEnd]}>
                        {pipelineColumnLabels[PipelineColumn.nonCallPeriodEndYears]}
                        <IconSVG name="info" width={16} height={16} />
                    </OnHoverTooltip>,
                renderColumnContent: ({ transaction }) =>
                    dateTimeUtils.yrsLeftToDateFrom(
                        transaction.nonCallPeriodEnd,
                        transaction.closingDate,
                        false
                    ) || constants.emptyPlaceholder,
                sortingField: PipelineColumn.nonCallPeriodEndDays,
                headerClassName: "data-list-cell-sm text-right",
                bodyClassName: "data-list-cell-sm text-right popover-column",
            },
            {
                columnKey: PipelineColumn.outOfNC,
                renderColumnHeaderContent: () =>
                    <OnHoverTooltip overlay={pipelineTooltipText[PipelineColumn.outOfNC]}>
                        {pipelineColumnLabels[PipelineColumn.outOfNC]}
                        <IconSVG name="info" width={16} height={16} />
                    </OnHoverTooltip>,
                renderColumnContent: ({ transaction }) =>
                    isNil(transaction.outOfNC)
                        ? constants.emptyPlaceholder
                        : formatUtils.formatBoolean(transaction.outOfNC),
                sortingField: PipelineColumn.outOfNC,
                headerClassName: "data-list-cell-sm",
                bodyClassName: "data-list-cell-sm popover-column text-capitalize",
            },
            {
                columnKey: PipelineColumn.reinvestmentPeriodEnd,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.reinvestmentPeriodEnd],
                renderColumnContent: ({ transaction }) =>
                    transaction.reinvestmentPeriodEnd
                        ? moment(transaction.reinvestmentPeriodEnd).format(constants.dateFormat)
                        : constants.emptyPlaceholder,
                sortingField: PipelineColumn.reinvestmentPeriodEnd,
                headerClassName: "data-list-cell-sm",
                bodyClassName: "data-list-cell-sm popover-column",
            },
            {
                columnKey: PipelineColumn.reinvestmentPeriodEndDays,
                renderColumnHeaderContent: () =>
                    <OnHoverTooltip overlay={pipelineTooltipText[PipelineColumn.reinvestmentPeriodEnd]}>
                        {pipelineColumnLabels[PipelineColumn.reinvestmentPeriodEndYears]}
                        <IconSVG name="info" width={16} height={16} />
                    </OnHoverTooltip>,
                renderColumnContent: ({ transaction }) =>
                    dateTimeUtils.yrsLeftToDateFrom(
                        transaction.reinvestmentPeriodEnd,
                        transaction.closingDate,
                        false
                    ) || constants.emptyPlaceholder,
                sortingField: PipelineColumn.reinvestmentPeriodEndDays,
                headerClassName: "data-list-cell-sm text-right",
                bodyClassName: "data-list-cell-sm text-right popover-column",
            },
            {
                columnKey: PipelineColumn.outOfRI,
                renderColumnHeaderContent: () =>
                    <OnHoverTooltip overlay={pipelineTooltipText[PipelineColumn.outOfRI]}>
                        {pipelineColumnLabels[PipelineColumn.outOfRI]}
                        <IconSVG name="info" width={16} height={16} />
                    </OnHoverTooltip>,
                renderColumnContent: ({ transaction }) =>
                    isNil(transaction.outOfRI)
                        ? constants.emptyPlaceholder
                        : formatUtils.formatBoolean(transaction.outOfRI),
                sortingField: PipelineColumn.outOfRI,
                headerClassName: "data-list-cell-sm",
                bodyClassName: "data-list-cell-sm popover-column text-capitalize",
            },
            {
                columnKey: PipelineColumn.vintage,
                renderColumnHeaderContent: () =>
                    pipelineColumnLabels[PipelineColumn.vintage],
                renderColumnContent: (transactionClass) => {
                    const vintage =
                        (transactionClass as OriginatingTransactionClass).transaction.vintage;
                    return vintage
                        ? moment(vintage).year()
                        : constants.emptyPlaceholder;
                },
                sortingField: PipelineColumn.vintage,
            },
            {
                columnKey: PipelineColumn.was,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.was],
                renderColumnContent: (transactionClass) =>
                    formatUtils.formatDecimal((transactionClass as OriginatingTransactionClass).transaction.was) ||
                    constants.emptyPlaceholder,
                className: "text-right",
                sortingField: PipelineColumn.was,
            },
            {
                columnKey: PipelineColumn.warf,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.warf],
                renderColumnContent: (transactionClass) => {
                    const warf = (transactionClass as OriginatingTransactionClass).transaction.warf;
                    return warf ? moneyUtils.money(warf, true) : constants.emptyPlaceholder;
                },
                className: "text-right",
                sortingField: PipelineColumn.warf,
            },
            {
                columnKey: PipelineColumn.ds,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.ds],
                renderColumnContent: (transactionClass) =>
                    formatUtils.formatDecimal((transactionClass as OriginatingTransactionClass).transaction.ds) ||
                    constants.emptyPlaceholder,
                className: "data-list-cell-xs text-right",
                sortingField: PipelineColumn.ds,
            },
            {
                columnKey: PipelineColumn.ticker,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.ticker],
                renderColumnContent: (transactionClass) => {
                    const value =
                        transactionClass.transaction.type === TransactionType.amr
                            ? (transactionClass as AmrClass).class.ticker144A
                            : (transactionClass as OriginatingTransactionClass)
                                .tranche?.ticker144A;

                    return value || constants.emptyPlaceholder;
                },
                className: "data-list-cell-lg",
                sortingField: PipelineColumn.ticker,
            },
            {
                columnKey: PipelineColumn.cusip,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.cusip],
                renderColumnContent: (transactionClass) => {
                    const value =
                        transactionClass.transaction.type === TransactionType.amr
                            ? (transactionClass as AmrClass).class.cusiP144A
                            : (transactionClass as OriginatingTransactionClass)
                                .tranche?.cusiP144A;

                    return value || constants.emptyPlaceholder;
                },
                className: "data-list-cell-md",
                sortingField: PipelineColumn.cusip,
            },
            {
                columnKey: PipelineColumn.isin,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.isin],
                renderColumnContent: (transactionClass) => {
                    const value =
                        transactionClass.transaction.type === TransactionType.amr
                            ? (transactionClass as AmrClass).class.isiN144A
                            : (transactionClass as OriginatingTransactionClass)
                                .tranche?.isiN144A;

                    return value || constants.emptyPlaceholder;
                },
                className: "data-list-cell-sm",
                sortingField: PipelineColumn.isin,
            },
            {
                columnKey: PipelineColumn.tickerRegS,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.tickerRegS],
                renderColumnContent: (transactionClass) => {
                    const value =
                        transactionClass.transaction.type === TransactionType.amr
                            ? (transactionClass as AmrClass).class.tickerRegS
                            : (transactionClass as OriginatingTransactionClass)
                                .tranche?.tickerRegS;

                    return value || constants.emptyPlaceholder;
                },
                className: "data-list-cell-lg",
                sortingField: PipelineColumn.tickerRegS,
            },
            {
                columnKey: PipelineColumn.cusipRegS,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.cusipRegS],
                renderColumnContent: (transactionClass) => {
                    const value =
                        transactionClass.transaction.type === TransactionType.amr
                            ? (transactionClass as AmrClass).class.cusipRegS
                            : (transactionClass as OriginatingTransactionClass)
                                .tranche?.cusipRegS;

                    return value || constants.emptyPlaceholder;
                },
                className: "data-list-cell-md",
                sortingField: PipelineColumn.cusipRegS,
            },
            {
                columnKey: PipelineColumn.isinRegS,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.isinRegS],
                renderColumnContent: (transactionClass) => {
                    const value =
                        transactionClass.transaction.type === TransactionType.amr
                            ? (transactionClass as AmrClass).class.isinRegS
                            : (transactionClass as OriginatingTransactionClass)
                                .tranche?.isinRegS;

                    return value || constants.emptyPlaceholder;
                },
                className: "data-list-cell-md",
                sortingField: PipelineColumn.isinRegS,
            },
            {
                columnKey: PipelineColumn.tickerAccdInvCertif,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.tickerAccdInvCertif],
                renderColumnContent: (transactionClass) => {
                    const value =
                        transactionClass.transaction.type === TransactionType.amr
                            ? (transactionClass as AmrClass).class.tickerAccdInvCertif
                            : (transactionClass as OriginatingTransactionClass)
                                .tranche?.tickerAccdInvCertif;

                    return value || constants.emptyPlaceholder;
                },
                className: "data-list-cell-lg-02",
                sortingField: PipelineColumn.tickerAccdInvCertif,
            },
            {
                columnKey: PipelineColumn.cusipAccdInvestor,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.cusipAccdInvestor],
                renderColumnContent: (transactionClass) => {
                    const value =
                        transactionClass.transaction.type === TransactionType.amr
                            ? (transactionClass as AmrClass).class.cusipAccdInvCertif
                            : (transactionClass as OriginatingTransactionClass)
                                .tranche?.cusipAccdInvCertif;

                    return value || constants.emptyPlaceholder;
                },
                className: "data-list-cell-lg-02",
                sortingField: PipelineColumn.cusipAccdInvestor,
            },
            {
                columnKey: PipelineColumn.isinAccdInvestor,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.isinAccdInvestor],
                renderColumnContent: (transactionClass) => {
                    const value =
                        transactionClass.transaction.type === TransactionType.amr
                            ? (transactionClass as AmrClass).class.isinAccdInvCertif
                            : (transactionClass as OriginatingTransactionClass)
                                .tranche?.isinAccdInvCertif;

                    return value || constants.emptyPlaceholder;
                },
                className: "data-list-cell-lg-02",
                sortingField: PipelineColumn.isinAccdInvestor,
            },
            {
                columnKey: PipelineColumn.rating,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.rating],
                renderColumnContent: (transactionClass) =>
                    `${transactionClass.transaction.type === TransactionType.amr
                        ? (transactionClass as AmrClass).ratingMoodys ||
                        constants.emptyPlaceholder
                        : (transactionClass as OriginatingTransactionClass)
                            .expectedRatingMoodys ||
                        constants.emptyPlaceholder
                    }/${transactionClass.transaction.type === TransactionType.amr
                        ? (transactionClass as AmrClass).ratingSnP ||
                        constants.emptyPlaceholder
                        : (transactionClass as OriginatingTransactionClass)
                            .expectedRatingSnP || constants.emptyPlaceholder
                    }/${transactionClass.transaction.type === TransactionType.amr
                        ? (transactionClass as AmrClass).ratingFitch ||
                        constants.emptyPlaceholder
                        : (transactionClass as OriginatingTransactionClass)
                            .expectedRatingFitch || constants.emptyPlaceholder
                    }/${transactionClass.transaction.type === TransactionType.amr
                        ? (transactionClass as AmrClass).ratingKbra ||
                        constants.emptyPlaceholder
                        : (transactionClass as OriginatingTransactionClass)
                            .expectedRatingKbra || constants.emptyPlaceholder
                    }/${transactionClass.transaction.type === TransactionType.amr
                        ? (transactionClass as AmrClass).ratingDbrs ||
                        constants.emptyPlaceholder
                        : (transactionClass as OriginatingTransactionClass)
                            .expectedRatingDbrs || constants.emptyPlaceholder
                    }`,
                className: "data-list-cell-xl",
                sortingField: PipelineColumn.rating,
            },
            {
                columnKey: PipelineColumn.balance,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.balance],
                renderColumnContent: (transactionClass) =>
                    transactionClass.balance != null && numericUtils.isNumber(transactionClass.balance)
                        ? moneyUtils.money(transactionClass.balance)
                        : constants.emptyPlaceholder,
                className: "data-list-cell-sm text-right",
                sortingField: PipelineColumn.balance,
            },
            {
                columnKey: PipelineColumn.parSubordination,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.parSubordination],
                renderColumnContent: (transactionClass) =>
                    transactionClass.parSubordination
                        ? formatUtils.formatDecimal(
                            transactionClass.parSubordination
                        )
                        : constants.emptyPlaceholder,
                className: "text-right",
                sortingField: PipelineColumn.parSubordination,
            },
            {
                columnKey: PipelineColumn.mvoc,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.mvoc],
                renderColumnContent: (transactionClass) =>
                    transactionClass.mvoc
                        ? formatUtils.formatDecimal(transactionClass.mvoc)
                        : constants.emptyPlaceholder,
                className: "text-right",
                sortingField: PipelineColumn.mvoc,
            },
            {
                columnKey: PipelineColumn.wal,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.wal],
                renderColumnContent: (transactionClass) =>
                    transactionClass.wal
                        ? formatUtils.formatDecimal(transactionClass.wal)
                        : constants.emptyPlaceholder,
                className: "data-list-cell-xs text-right",
                sortingField: PipelineColumn.wal,
            },
            {
                columnKey: PipelineColumn.coupon,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.coupon],
                renderColumnContent: ({ floaterIndex, margin, transaction }) =>
                    transaction.type === TransactionType.amr
                        ? constants.emptyPlaceholder
                        : amrFormatUtils.formatInventoryCoupon(floaterIndex, margin),
                className: "data-list-cell-md",
                sortingField: PipelineColumn.margin,
            },
            {
                columnKey: PipelineColumn.dm,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.dm],
                renderColumnContent: (transactionClass) =>
                    <ImSubscriptionValue transaction={transactionClass.transaction}>
                        {(transactionClass as OriginatingTransactionClass).dm ?? constants.emptyPlaceholder}
                    </ImSubscriptionValue>,
                className: "text-right",
                sortingField: PipelineColumn.dm,
            },
            {
                columnKey: PipelineColumn.initialPrice,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.initialPrice],
                renderColumnContent: (transactionClass) => (
                    <ImSubscriptionValue transaction={transactionClass.transaction}>
                        { transactionClass.initialPrice ?
                            <OnHoverTooltip overlay={transactionClass.initialPrice}>
                                <span className="text-ellipsis">{transactionClass.initialPrice}</span>
                            </OnHoverTooltip>
                            : constants.emptyPlaceholder
                        }
                    </ImSubscriptionValue>
                ),
                className: "data-list-cell-lg",
                sortingField: PipelineColumn.initialPrice,
            },
            {
                columnKey: PipelineColumn.price,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.price],
                renderColumnContent: (transactionClass) => (
                    <ImSubscriptionValue transaction={transactionClass.transaction}>
                        {transactionClass.price ?
                            <OnHoverTooltip overlay={transactionClass.price}>
                                <span className="text-ellipsis">{transactionClass.price}</span>
                            </OnHoverTooltip>
                            : constants.emptyPlaceholder
                        }
                    </ImSubscriptionValue>
                ),
                className: "data-list-cell-xs text-right",
                sortingField: PipelineColumn.price,
            },
            {
                columnKey: PipelineColumn.initialGuidance,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.initialGuidance],
                renderColumnContent: (transactionClass) => (
                    <ImSubscriptionValue transaction={transactionClass.transaction}>
                        { transactionClass.initialGuidance ?
                            <OnHoverTooltip overlay={transactionClass.initialGuidance}>
                                <span className="text-ellipsis">{transactionClass.initialGuidance}</span>
                            </OnHoverTooltip>
                            : constants.emptyPlaceholder
                        }
                    </ImSubscriptionValue>
                ),
                className: "data-list-cell-lg",
                sortingField: PipelineColumn.initialGuidance,
            },
            {
                columnKey: PipelineColumn.guidance,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.guidance],
                renderColumnContent: (transactionClass) => (
                    <ImSubscriptionValue transaction={transactionClass.transaction}>
                        {transactionClass.guidance ?
                            <LastUpdatedPopover
                                transaction={transactionClass.transaction}
                                classReferenceName={transactionClass.referenceName}
                                field="guidance"
                            >
                                <OnHoverTooltip overlay={transactionClass.guidance}>
                                    <button className="pseudo-link">
                                        {transactionClass.guidance}
                                    </button>
                                </OnHoverTooltip>
                            </LastUpdatedPopover>
                            : constants.emptyPlaceholder
                        }
                    </ImSubscriptionValue>
                ),
                className: "data-list-cell-lg",
                sortingField: PipelineColumn.guidance,
            },
            {
                columnKey: PipelineColumn.originatingTransactionClassStatus,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.originatingTransactionClassStatus],
                renderColumnContent: transactionClass => {
                    const { originatingTransactionClassStatus } = (transactionClass as OriginatingTransactionClass);

                    return (
                        <ImSubscriptionValue transaction={transactionClass.transaction}>
                            {originatingTransactionClassStatus
                                ? transactionClassStatusTitles[originatingTransactionClassStatus]
                                : constants.emptyPlaceholder
                            }
                        </ImSubscriptionValue>
                    );
                },
                className: "data-list-cell-sm",
                sortingField: "originatingTransactionClassStatus",
                sortingType: PipelineColumn.originatingTransactionClassStatus,
            },
            {
                columnKey: PipelineColumn.subscription,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.subscription],
                renderColumnContent: (transactionClass) => (
                    <ImSubscriptionValue transaction={transactionClass.transaction}>
                        {transactionClass.transaction.type !== TransactionType.amr && (transactionClass as OriginatingTransactionClass).subscription ?
                            <LastUpdatedPopover
                                transaction={transactionClass.transaction}
                                classReferenceName={transactionClass.referenceName}
                                field="subscription"
                            >
                                <OnHoverTooltip overlay={(transactionClass as OriginatingTransactionClass).subscription}>
                                    <button className="pseudo-link">
                                        {(transactionClass as OriginatingTransactionClass).subscription}
                                    </button>
                                </OnHoverTooltip>
                            </LastUpdatedPopover>
                            : constants.emptyPlaceholder
                        }
                    </ImSubscriptionValue>
                ),
                className: "data-list-cell-lg",
                sortingField: PipelineColumn.subscription,
            },
            {
                columnKey: PipelineColumn.target,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.target],
                renderColumnContent: (transactionClass) =>
                    formatUtils.formatDecimal(
                        (transactionClass as OriginatingTransactionClass).ocTarget
                    ) || constants.emptyPlaceholder,
                className: "data-list-cell-sm text-right",
                sortingField: "ocTarget",
            },
            {
                columnKey: PipelineColumn.trigger,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.trigger],
                renderColumnContent: (transactionClass) =>
                    formatUtils.formatDecimal(
                        (transactionClass as OriginatingTransactionClass).ocTrigger
                    ) || constants.emptyPlaceholder,
                className: "data-list-cell-sm text-right",
                sortingField: "ocTrigger",
            },
            {
                columnKey: PipelineColumn.cushion,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.cushion],
                renderColumnContent: (transactionClass) =>
                    (transactionClass as OriginatingTransactionClass).ocCushion
                        ? formatUtils.formatDecimal(
                            (transactionClass as OriginatingTransactionClass)
                                .ocCushion
                        )
                        : constants.emptyPlaceholder,
                className: "data-list-cell-md text-right",
                sortingField: "ocCushion",
            },
            {
                columnKey: PipelineColumn.margin,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.margin],
                renderColumnContent: (transactionClass) =>
                    transactionClass.margin
                        ? formatUtils.formatMargin(transactionClass.margin)
                        : constants.emptyPlaceholder,
                className: "data-list-cell-sm text-right",
                sortingField: PipelineColumn.margin,
            },
            {
                columnKey: PipelineColumn.factor,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.factor],
                renderColumnContent: (transactionClass) =>
                    formatUtils.formatFactor(
                        (transactionClass as AmrClass).class?.factor
                    ) || constants.emptyPlaceholder,
                className: "text-right",
                sortingField: PipelineColumn.factor,
            },
            {
                columnKey: PipelineColumn.capMargin,
                renderColumnHeaderContent: () => (
                    <OnHoverTooltip overlay={"Maximum margin which was accepted for the auction"} placement="bottomRight">
                        <span>{pipelineColumnLabels[PipelineColumn.capMargin]}</span>
                    </OnHoverTooltip>
                ),
                renderColumnContent: (transactionClass) =>
                    (transactionClass as AmrClass).capMargin
                        ? formatUtils.formatMargin(
                            (transactionClass as AmrClass).capMargin
                        )
                        : constants.emptyPlaceholder,
                className: "data-list-cell-sm text-right",
                sortingField: PipelineColumn.capMargin,
            },
            {
                columnKey: PipelineColumn.nonAmr,
                renderColumnHeaderContent: () => (
                    <OnHoverTooltip overlay={"Number of months before another AMR can be started"} placement="bottomRight">
                        <span>{pipelineColumnLabels[PipelineColumn.nonAmr]}</span>
                    </OnHoverTooltip>
                ),
                renderColumnContent: (transactionClass) => (
                    <NonAmrPeriod
                        period={(transactionClass as AmrClass).nonAmrPeriod}
                    />
                ),
                className: "data-list-cell-sm",
                sortingField: "nonAMRPeriod",
            },
            {
                columnKey: PipelineColumn.euCompliance,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.euCompliance],
                renderColumnContent: (transactionClass) => {
                    const {
                        dealLegalName,
                        euCompliance,
                        euRiskRetentionRequirements,
                        euDisclosureRequirements
                    } = transactionClass.transaction;
                    const columnText = formatUtils.formatBooleanWithPlaceholder(euCompliance);

                    if (euCompliance && (euRiskRetentionRequirements || euDisclosureRequirements)) {
                        return (
                            <Popover
                                title="EU Compliance"
                                className="popover-eu-compliance"
                                label={<span className="name-tag text-ellipsis">{dealLegalName}</span>}
                                actionButton={
                                    <button className="pseudo-link">
                                        {columnText}
                                    </button>
                                }
                            >
                                <h2 className="title-border">EU Risk Retention Requirements</h2>
                                <div className="popover-text-box">
                                    {renderMarkdownOrEmpty(euRiskRetentionRequirements)}
                                </div>
                                <h2 className="title-border">EU Disclosure Requirements</h2>
                                <div>
                                    {renderMarkdownOrEmpty(euDisclosureRequirements)}
                                </div>
                            </Popover>
                        );
                    }

                    return columnText;
                },
                className: "data-list-cell-sm",
                sortingField: PipelineColumn.euCompliance,
            },
            {
                columnKey: PipelineColumn.esg,
                renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.esg],
                renderColumnContent: (transactionClass) => {
                    const { dealLegalName, esg, esgComment } = transactionClass.transaction;;
                    const columnText = formatUtils.formatBooleanWithPlaceholder(esg);

                    if (esg && esgComment) {
                        return (
                            <Popover
                                title="ESG Comment"
                                label={<span className="name-tag text-ellipsis">{dealLegalName}</span>}
                                actionButton={
                                    <button className="pseudo-link">
                                        {columnText}
                                    </button>
                                }
                                className="popover-esg-comments"
                            >
                                <MarkdownViewer value={esgComment} />
                            </Popover>
                        );
                    }

                return columnText;
            },
            className: "data-list-cell-xs",
            sortingField: PipelineColumn.esg,
        },
        {
            columnKey: PipelineColumn.collateralType,
            renderColumnHeaderContent: () => 'Collat.',
            renderColumnContent: (transaction) =>
                formatUtils.formatCollateralType((transaction as AmrClass).transaction.collateralType),
            className: "data-list-cell-xs",
            sortingField: PipelineColumn.collateralType,
        },
        {
            columnKey: PipelineColumn.currency,
            renderColumnHeaderContent: () => 'Ccy',
            renderColumnContent: (transaction) =>
                (transaction as AmrClass).transaction.currency?.code || constants.emptyPlaceholder,
            className: "data-list-cell-xs",
            sortingField: PipelineColumn.currency,
        },
        {
            columnKey: PipelineColumn.amrClass,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.amrClass],
            renderColumnContent: (transaction) => {
                const amr =
                    transaction.transaction.type === TransactionType.amr
                        ? (transaction as AmrClass).class?.amr
                        : (transaction as OriginatingTransactionClass).amr;
                return capitalize(formatUtils.formatBoolean(amr));
            },
            className: "data-list-cell-sm",
            sortingField: PipelineColumn.amr,
        },
        {
            columnKey: PipelineColumn.maturity,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.maturity],
            renderColumnContent: (transactionClass) => {
                const maturity =
                    (transactionClass as OriginatingTransactionClass).transaction.statedMaturity ||
                    (transactionClass as AmrClass).transaction.finalMaturity;
                return maturity
                    ? moment(maturity).format(constants.dateFormat)
                    : constants.emptyPlaceholder;
            },
            className: "data-list-cell-sm",
            sortingField: PipelineColumn.maturity,
        },
        {
            columnKey: PipelineColumn.firstPayment,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.firstPayment],
            renderColumnContent: (transactionClass) =>
                transactionClass.transaction.firstPaymentDate
                    ? moment(transactionClass.transaction.firstPaymentDate).format(
                          constants.dateFormat
                      )
                    : constants.emptyPlaceholder,
            className: "data-list-cell-sm",
            sortingField: PipelineColumn.firstPayment,
        },
        {
            columnKey: PipelineColumn.intex,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.intex],
            renderColumnContent: (transactionClass) => {
                const { intexName, intexPassword } =
                    (transactionClass as OriginatingTransactionClass).transaction;
                return !intexName && !intexPassword
                    ? constants.emptyPlaceholder
                    : <OnHoverTooltip overlay={(intexName || constants.emptyPlaceholder) + " / " + (intexPassword || constants.emptyPlaceholder)}>
                        {(intexName || constants.emptyPlaceholder) + " / " + (intexPassword || constants.emptyPlaceholder)}
                    </OnHoverTooltip>
            },
            className: "data-list-cell-xl",
        },
        {
            columnKey: PipelineColumn.numberOfDocuments,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.numberOfDocuments],
            renderColumnContent: (transactionClass, { userCompany }) => {
                const isMedia = user.hasRoles(roles.Media);

                if (!transactionClass.transaction.numberOfDocuments) {
                    return constants.emptyPlaceholder;
                }

                return !isMedia &&
                    (transactionClass.transaction.type === TransactionType.amr || (canViewTransactionDetails() && !hasLimitedAccess(transactionClass.transaction, userCompany))) ? (
                        <a
                            className="text-ellipsis"
                            href={routes.transactionDetailUrl(
                                transactionClass.transaction.referenceName,
                                transactionClass.transaction.dealReferenceName,
                                'documents',
                            )}
                            target="_blank"
                            rel="noreferrer"
                            onClick={e => e.stopPropagation()}
                        >
                            View ({transactionClass.transaction.numberOfDocuments})
                        </a>
                    ) : (
                        <span className="text-ellipsis">{transactionClass.transaction.numberOfDocuments}</span>
                    );
            },
            sortingField: PipelineColumn.numberOfDocuments,
        },
        {
            columnKey: PipelineColumn.syndicate,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.syndicate],
            renderColumnContent: (transactionClass, context) => {
                const { dispatch, actions } = context;
                const { transaction } = (transactionClass as OriginatingTransactionClass)
                const numberOfContacts = transaction.numberOfSyndicateContacts;

                return numberOfContacts ? (
                    <span
                        className="text-ellipsis pseudo-link"
                        onClick={(e) => {
                            e.stopPropagation();
                            dispatch(
                                actions.setSyndicateContacts(
                                    transaction.dealLegalName,
                                    transaction.dealReferenceName,
                                    transaction.referenceName,
                                    transaction.arranger?.legalName
                                )
                            );
                        }}
                    >
                        {numberOfContacts} contact{numberOfContacts > 1 ? 's' : ''}
                    </span>
                ) : constants.emptyPlaceholder
            },
            className: "data-list-cell-sm",
            sortingField: PipelineColumn.syndicate,
        },
        {
            columnKey: PipelineColumn.enhancedCLO,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.enhancedCLO],
            renderColumnContent: ({ transaction }) =>
                formatUtils.formatBooleanWithPlaceholder(transaction.enhancedCLO),
            className: "data-list-cell-sm",
            sortingField: PipelineColumn.enhancedCLO,
        },
        {
            columnKey: PipelineColumn.staticDeal,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.staticDeal],
            renderColumnContent: ({ transaction }) =>
                formatUtils.formatBooleanWithPlaceholder(transaction.staticDeal),
            className: "data-list-cell-sm",
            sortingField: PipelineColumn.staticDeal,
        },
        {
            columnKey: PipelineColumn.iois,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.iois],
            renderColumnContent: (transactionClass) =>
                formatUtils.formatBooleanWithPlaceholder(!!(transactionClass as OriginatingTransactionClass).numberOfIOIs),
            className: "data-list-cell-xs",
        },
        {
            columnKey: PipelineColumn.options,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.options],
            renderColumnContent: () => ""
        },
    ];

    const customizedColumns = columnsConfig
        .reduce((acc: ColumnBuilder<OriginatingTransactionClass | AmrClass>[], columnConfig: UserConfigColumn) => {
            const column = classesColumnDefinitions.find(
                ({ columnKey }) => columnKey.toLocaleLowerCase() === columnConfig.name.toLocaleLowerCase()
            );

            if (!column) {
                return acc;
            }

            if (column.columnKey === PipelineColumn.options) {
                const isShareAvailable = user.hasRoles(...roles.bd());
                const isButtonAvailable = user.hasRoles(
                    ...roles.getAllRolesExclude(
                        ...roles.admin(),
                        roles.DataEntry,
                        roles.Viewer,
                        roles.Media,
                    )
                );

                return [
                    ...acc,
                    new ColumnBuilder(collapseStickRightColumn),
                    ...(isButtonAvailable
                        ? [new ColumnBuilder(optionsButtonColumnDefinition)]
                        : []),
                    ...(isShareAvailable
                        ? [new ColumnBuilder(shareOptionColumnDefinition)]
                        : []),
                    new ColumnBuilder(optionsColumnDefinition),
                ];
            }

            return [...acc, new ColumnBuilder(column)];
        }, []);

    return fixedColumnDefinitions
        .map(column => new ColumnBuilder(column))
        .concat(customizedColumns);
};
