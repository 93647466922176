import { useContext, useState } from "react";
import cn from 'classnames';
import { useSelector } from "react-redux";
import { createAmrPipelineActions } from "../../../actions/amr-pipeline.actions";
import { SORT, constants, roles } from "../../../constants";
import { OriginatingTransaction } from "../../../types/amr-pipeline/models/OriginatingTransaction";
import { AmrTransaction } from "../../../types/amr-pipeline/models/AmrTransaction";
import { AppState } from "../../../types/state/AppState";
import { PipelineView } from "../types/PipelineView";
import { OriginatingTransactionClass } from "../../../types/amr-pipeline/models/OriginatingTransactionClass";
import { AmrClass } from "../../../types/amr-pipeline/models/AmrClass";
import { ColumnBuilder } from "../../bidding/common/table/columns/column-builder/ColumnBuilder";
import { Table } from "../../bidding/common/table";
import { TransactionType } from "../../../types/amr-pipeline/enums/TransactionType";
import { amrColumns } from "./deal-view/columns/amr-columns";
import { newIssueColumns } from "./deal-view/columns/new-issue-columns";
import { BaseClass } from "../../../types/amr-pipeline/models/BaseClass";
import { LazyLoad } from "../../common/LazyLoad";
import { EmptyPlaceholder } from '../../common';
import { OriginatingTransactionClassStatus } from "../../../types/amr-pipeline/enums/OriginatingTransactionClassStatus";
import PipelineContext from './PipelineContext';
import { amrDealsSelector } from '../../../selectors/amr-pipeline.selector';
import { TableColumnStickType } from "../../bidding/common/table/types/TableColumnStickType";
import { SecurityListHeader } from "../../common/security-list";
import { StickyTableRow } from "../../bidding/common/table/sticky/StickyTableRow";
import { PipelineType } from "../../../types/amr-pipeline/enums/PipelineType";
import { user } from "../../../user";
import { arrangerColumns, investorColumns } from "./iois/iois.columns";
import { StickyTableCollapsibleRow } from "../../bidding/common/table/sticky/StickyTableCollapsibleRow";
import { InviteClientsTransactionPopup } from "../common/InviteClientsTransactionPopup/InviteClientsTransactionPopup";
import { IntexPopup } from "../detailed/IntexPopup";
import { IOI } from "../../../types/amr-pipeline/models/IOI";
import { TransactionAlertsPopup } from "../common/transactionAlerts/TransactionAlertsPopup";
import { SubscriptionFeature } from "../../../types/billing/SubscriptionFeature";
import { BlockedFeatureContent, SubscribeLink } from "../../../components/access/BlockedFeatureText";
import { useAppDispatch } from "../../../effects/useAppDispatch";

interface Props {
    dataItems: (
        | OriginatingTransaction
        | AmrTransaction
        | OriginatingTransactionClass
        | AmrClass
    )[];
    columns: ColumnBuilder<any>[];
    className?: string;
    view?: PipelineView;
}

export function PipelineList({
    dataItems,
    columns,
    className = '',
    view = PipelineView.Deal,
}: Props) {
    const { pipelineType, setTab, optionColumnCollapseStatus, setOptionColumnCollapseStatus } = useContext(PipelineContext);

    const [transactionToShare, setTransactionToShare] = useState(null);
    const [transactionForIntex, setTransactionForIntex] = useState(null);
    const [transactionForAlerts, setTransactionForAlerts] = useState(null);

    const actions = createAmrPipelineActions(pipelineType);
    const dispatch = useAppDispatch();

    const isOptionsColumnCollapsed = optionColumnCollapseStatus[pipelineType as PipelineType.Deals | PipelineType.IOIs];

    const {
        dealSortDirection,
        classSortDirection,
        dealSortBy,
        classSortBy,
        hasMoreTransactionsClasses,
        hasMoreTransactions,
        isTransactionsSearching,
        isClassesSearching,
        isExpanded,
        numberOfHiddenTransactions,
        numberOfHiddenClasses,
    } = useSelector(amrDealsSelector(pipelineType));

    const { userCompany } = useSelector((state: AppState) => state.issuanceMonitor.amrPipelineCommon);

    const isDealView = view === PipelineView.Deal;
    const sortBy = isDealView ? dealSortBy : classSortBy;
    const sortDirection = isDealView ? dealSortDirection : classSortDirection;
    const isIOIsTab = pipelineType === PipelineType.IOIs;

    const isSellerTrader = user.hasRoles(roles.SellerTrader);
    const isSellerViewer = user.hasRoles(roles.SellerViewer);

    const isSeller = isSellerTrader || isSellerViewer;

    const withImSubscription = user.hasFeatures(SubscriptionFeature.IssuanceMonitorFullAccess);
    const nonVisibleCount = isDealView ? numberOfHiddenTransactions : numberOfHiddenClasses;
    const hasMoreItems = isDealView ? hasMoreTransactions : hasMoreTransactionsClasses;
    const hasInvisibleDeals = !hasMoreItems && !!(nonVisibleCount && nonVisibleCount > 0);

    function setExpand(expanded: boolean) {
        dispatch(actions.setExpanded(expanded));
    }

    function loadTransactions() {
        if (!isTransactionsSearching) dispatch(actions.loadTransactions());
    }

    function loadClasses() {
        if (!isClassesSearching) dispatch(actions.loadClasses());
    }

    const onSort = (field: string) => {
        const switchSorting = (sort: string) => sort === SORT.ASC ? SORT.DESC : SORT.ASC;

        if (isDealView) {
            dispatch(
                actions.dealSortingChanged(
                    field,
                    switchSorting(dealSortDirection),
                )
            );
        } else {
            dispatch(
                actions.classSortingChanged(
                    field,
                    switchSorting(classSortDirection),
                )
            );
        }

        dispatch(actions.applyFilterAndSearch());
    };

    const renderDealCollapsibleItem = (
        transaction: AmrTransaction | OriginatingTransaction,
        columnType?: TableColumnStickType,
    ) => {
        const context = { dispatch, userCompany, actions, setTab, transaction };
        const headerContext = { transaction };

        const [left, middle, right] = transaction.type === TransactionType.amr ? amrColumns() : newIssueColumns();

        let collapsibleColumns: ColumnBuilder<AmrClass>[] | ColumnBuilder<OriginatingTransactionClass>[] = [];

        if (columnType === TableColumnStickType.Left) {
            collapsibleColumns = left;
        }

        if (columnType === TableColumnStickType.Middle) {
            collapsibleColumns = middle;
        }

        if (columnType === TableColumnStickType.Right) {
            collapsibleColumns = right;
        }

        return (
            <LazyLoad disabled={false} enabledUnloading className="component-collapsible-list-item-wrap">
                {(visible: boolean) => {
                    if (!transaction.classes.length) {
                        return (
                            <EmptyPlaceholder
                                text={columnType === TableColumnStickType.Middle && 'There are no added classes yet.'}
                                textViewRow
                            />
                        );
                    }

                    if (!visible) {
                        // Empty block with height to prevent twitch during scrolling
                        return (
                            <div
                                style={{
                                    height:
                                        constants.tableRowHeight * transaction.classes.length +
                                        constants.tableHeaderHeight,
                                }}
                            ></div>
                        );
                    }

                    return (
                        <>
                            <SecurityListHeader className={className}>
                                {collapsibleColumns.map(c =>
                                    c.renderHeader(undefined, undefined, undefined, headerContext),
                                )}
                            </SecurityListHeader>
                            {transaction.classes.map(tc =>
                                isIOIsTab && columnType ? (
                                    <StickyTableCollapsibleRow
                                        className=""
                                        key={tc.transactionReferenceName + tc.referenceName}
                                        rowKey={tc.transactionReferenceName + tc.referenceName}
                                        columns={collapsibleColumns as any[]}
                                        dataItem={tc}
                                        context={context}
                                        columnType={columnType}
                                        expandAll={false}
                                        collapseIconVisible={columnType === TableColumnStickType.Middle}
                                        renderCollapsibleContent={dataItem =>
                                            renderClassCollapsibleItem(
                                                dataItem as OriginatingTransactionClass,
                                                columnType,
                                            )
                                        }
                                    />
                                ) : (
                                    <StickyTableRow
                                        className={
                                            (tc as OriginatingTransactionClass).originatingTransactionClassStatus ===
                                            OriginatingTransactionClassStatus.NotOffered
                                                ? 'sold-status'
                                                : ''
                                        }
                                        key={tc.transactionReferenceName + tc.referenceName}
                                        rowKey={tc.transactionReferenceName + tc.referenceName}
                                        columns={collapsibleColumns as any[]}
                                        dataItem={tc}
                                        context={context}
                                    />
                                ),
                            )}
                        </>
                    );
                }}
            </LazyLoad>
        );
    };

    const renderClassCollapsibleItem = (tc?: OriginatingTransactionClass, columnType?: TableColumnStickType) => {
        if (pipelineType === PipelineType.Deals || !tc) {
            return null;
        }

        const context = { dispatch, isSeller, userCompany, actions, setTab };

        const [left, middle, right] = isSeller ? investorColumns() : arrangerColumns();
        let collapsibleColumns: ColumnBuilder<IOI>[] = [];

        if (columnType === TableColumnStickType.Left) {
            collapsibleColumns = left;
        }

        if (columnType === TableColumnStickType.Middle) {
            collapsibleColumns = middle;
        }

        if (columnType === TableColumnStickType.Right) {
            collapsibleColumns = right;
        }

        const iois = tc.ioIs;

        return (
            <LazyLoad disabled={false} enabledUnloading className="component-collapsible-list-item-wrap">
                {(visible: boolean) => {
                    if (!iois || !iois.length) {
                        return (
                            <EmptyPlaceholder
                                text={columnType !== TableColumnStickType.Middle ? '' : 'There are no added classes yet.'}
                                textViewRow
                            />
                        );
                    }

                    if (!visible) {
                        // Empty block with height to prevent twitch during scrolling
                        return (
                            <div
                                style={{
                                    height:
                                        constants.tableRowHeight * iois.length +
                                        constants.tableHeaderHeight,
                                }}
                            ></div>
                        );
                    }

                    return (
                        <>
                            <SecurityListHeader className={className}>
                                {collapsibleColumns.map(c =>
                                    c.renderHeader(),
                                )}
                            </SecurityListHeader>
                            {iois.map(ioi => (
                                <StickyTableRow
                                    className="collapsed-content-level-2"
                                    key={ioi.classReferenceName + ioi.submitDate}
                                    rowKey={ioi.classReferenceName + ioi.submitDate}
                                    columns={collapsibleColumns as any[]}
                                    dataItem={ioi}
                                    context={context}
                                />
                            ))}
                        </>
                    );
                }}
            </LazyLoad>
    )};

    const renderCollapsibleItem = () => {
        return isDealView ? renderDealCollapsibleItem : renderClassCollapsibleItem;
    }

    const renderLastRow = () => {
        if (!hasInvisibleDeals || withImSubscription) {
            return null;
        }

        const description = `to see ${nonVisibleCount} more ${isDealView ? 'deal(s)' : 'class(es)' }.`;

        return (
            <BlockedFeatureContent
                inline
                className="invisible-data-placeholder"
                text={<><SubscribeLink /> {description}</>}
            />
        );
    }

    return (
        <>
            <Table
                onSort={onSort}
                infiniteScrollEnabled={hasMoreItems}
                defaultSortBy={sortBy}
                defaultSortByDirection={sortDirection}
                onNextPageRequest={isDealView ? loadTransactions : loadClasses}
                sticky
                className={cn(className, {
                    "data-list-iois": isIOIsTab
                })}
                dataItems={dataItems}
                createRowCustomKey={(item: OriginatingTransaction | AmrTransaction | BaseClass, index: number) => {
                    return isDealView
                        ? item.referenceName + item.dealReferenceName
                        : item.referenceName + item.dealReferenceName + (item as BaseClass).transactionReferenceName
                }}
                createSecurityCustomClassName={isDealView
                    ? null
                    : (c: OriginatingTransactionClass) =>
                        c.originatingTransactionClassStatus === OriginatingTransactionClassStatus.NotOffered ? 'sold-status' : ''
                }
                createHeaderCustomArgs={(item: OriginatingTransaction | AmrTransaction | BaseClass) => ({
                    isOptionsColumnCollapsed: isOptionsColumnCollapsed,
                    setIsOptionsColumnCollapsed: setOptionColumnCollapseStatus,
                })}
                createSecurityCustomArgs={(item: OriginatingTransaction | AmrTransaction | BaseClass) => ({
                    dispatch,
                    userCompany,
                    actions,
                    transactionReferenceName: (item as BaseClass).transactionReferenceName,
                    setTransactionToShare,
                    setTransactionForIntex,
                    setTransactionForAlerts,
                    setTab
                })}
                columns={columns}
                collapsible={isIOIsTab || isDealView}
                defaultExpandAll={isExpanded}
                onExpandAll={setExpand}
                renderCollapsibleItem={renderCollapsibleItem()}
                renderLastRow={renderLastRow}
            />
            {transactionToShare && (
                <InviteClientsTransactionPopup
                    transaction={transactionToShare}
                    onClose={() => setTransactionToShare(null)}
                    withGetTransaction={!isDealView || isIOIsTab}
                />
            )}
            {transactionForIntex &&
                <IntexPopup
                    transaction={transactionForIntex}
                    onCloseClick={() => setTransactionForIntex(null)}
                />
            }
            {transactionForAlerts &&
                <TransactionAlertsPopup
                    onClose={() => setTransactionForAlerts(null)}
                    transaction={transactionForAlerts}
                />
            }
        </>
    );
}
