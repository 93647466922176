import { Dispatch } from "react";
import { createAmrPipelineActions } from "../../../../../actions";
import { constants, pipelineColumnLabels } from "../../../../../constants";
import IconSVG from "../../../../../styles/svg-icons";
import { OriginatingTransactionClassStatus } from "../../../../../types/amr-pipeline/enums/OriginatingTransactionClassStatus";
import { PipelineType } from "../../../../../types/amr-pipeline/enums/PipelineType";
import { TransactionStatus } from "../../../../../types/amr-pipeline/enums/TransactionStatus";
import { TransactionType } from "../../../../../types/amr-pipeline/enums/TransactionType";
import { AmrClass } from "../../../../../types/amr-pipeline/models/AmrClass";
import { AmrTransaction } from "../../../../../types/amr-pipeline/models/AmrTransaction";
import { Company } from "../../../../../types/amr-pipeline/models/Company";
import { OriginatingTransaction } from "../../../../../types/amr-pipeline/models/OriginatingTransaction";
import { OriginatingTransactionClass } from "../../../../../types/amr-pipeline/models/OriginatingTransactionClass";
import { isBrokerDealersOwnTransaction } from "../../../../../utils/amr-pipeline.utils";
import { IColumnDefinition } from "../../../../bidding/common/table/types/ColumnDefinition";
import { OnHoverTooltip } from "../../../../common";
import { TransactionHistoryLink } from "../../../common/TransactionHistoryLink";
import { PipelineColumn } from "../../../types/PipelineColumn";
import { IssuanceMonitorTab } from "../../../types/PipelineTabTypes";
import { IntexButton } from "../../IntexButton/IntexButton";
import { OptionsButton } from "../../OptionsButton";
import { ImSubscriptionActionBlocker } from "../../../subscription/ImSubscriptionActionBlocker";
import { BloombergLink } from "../../../../common/BloombergLink";
import { ValitanaLink } from "../../../../common/ValitanaLink";
import { TransactionAlertsButton } from "../../../common/transactionAlerts/TransactionAlertsButton";
import { BwicMonitorLink } from "../../../common/BwicMonitorLink";
import { BwicInventoryLink } from "../../../common/BwicInventoryLink";
import { AddToPortfolioLink } from "../../../common/AddToPortfolioLink";

export const shareOptionColumnDefinition: IColumnDefinition<
    OriginatingTransactionClass | AmrClass
> = {
    columnKey: PipelineColumn.shareOption,
    renderColumnHeaderContent: () => "",
    renderColumnContent: (
        transactionClass,
        context: {
            userCompany: Company;
            setTransactionToShare: (
                transaction: OriginatingTransaction | AmrTransaction
            ) => void;
        }
    ) => {
        const { userCompany, setTransactionToShare } = context;
        const showShare = isBrokerDealersOwnTransaction(
            transactionClass.transaction,
            userCompany
        );

        return (
            <div className="action-row">
                <div className="action-col">
                    {showShare ? (
                        <OnHoverTooltip overlay="Share Transaction">
                            <div
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setTransactionToShare(transactionClass.transaction);
                                }}
                                className="btn-link"
                            >
                                <IconSVG name="share" width={16} height={16} />
                            </div>
                        </OnHoverTooltip>
                    ) : <span className="action-empty-placeholder">{constants.emptyPlaceholder}</span>}
                </div>
            </div>
        );
    },
    headerClassName: "data-list-cell-xxxs data-list-cell-actions padding-r-0 text-right",
    bodyClassName: "data-list-cell-xxxs data-list-cell-actions padding-r-0 text-right",
    stickRight: true,
};

export const optionsColumnDefinition: IColumnDefinition<
    OriginatingTransactionClass | AmrClass
> = {
    columnKey:  PipelineColumn.optionsAction,
    renderColumnHeaderContent: () =>
        pipelineColumnLabels[PipelineColumn.options],
    renderColumnContent: (
        transactionClass,
        context: {
            userCompany: Company;
            dispatch: Dispatch<any>;
            setTab: (tab: IssuanceMonitorTab) => void;
            setTransactionForIntex: (
                transaction: OriginatingTransaction
            ) => void;
            setTransactionForAlerts: (
                transaction: OriginatingTransaction
            ) => void;
        }
    ) => {
        const { userCompany, dispatch, setTab, setTransactionForIntex, setTransactionForAlerts } =
            context;
        const { transaction } = transactionClass as OriginatingTransactionClass;
        const {
            referenceName,
            dealReferenceName,
            dealLegalName,
            numberOfDealPublishedTransactions,
            status,
            type,
            version,
        } = transaction;
        const isOriginatingTransaction = type !== TransactionType.amr;
        const showHistory =
            status !== TransactionStatus.Draft && isOriginatingTransaction;

        const actions = createAmrPipelineActions(PipelineType.Deals);

        const transactionWithClass = {
            ...transaction,
            classes: [transactionClass],
        } as OriginatingTransaction | AmrTransaction;

        const classIdentifiers = isOriginatingTransaction ? (transactionClass as OriginatingTransactionClass).tranche : (transactionClass as AmrClass).class

        return (
            <div className="action-row">
                <div className="action-col">
                    {numberOfDealPublishedTransactions > 1 ? (
                        <ImSubscriptionActionBlocker>
                            {blocked => (
                                <OnHoverTooltip overlay={!blocked ? 'Related Transactions' : null}>
                                    <button
                                        disabled={blocked}
                                        onClick={() => {
                                            setTab(IssuanceMonitorTab.Deals);
                                            dispatch(
                                                actions.changeDealSelectFromTable(dealLegalName, dealReferenceName),
                                            );
                                        }}
                                        className="btn-link"
                                    >
                                        <IconSVG name="related-transaction" width={16} height={16} />
                                    </button>
                                </OnHoverTooltip>
                            )}
                        </ImSubscriptionActionBlocker>
                    ) : (
                        <span className="action-empty-placeholder">{constants.emptyPlaceholder}</span>
                    )}
                </div>
                <div className="action-col">
                    {isOriginatingTransaction && status !== TransactionStatus.Closed ? (
                        <div onClick={e => e.stopPropagation()}>
                            <TransactionAlertsButton
                                transaction={transaction}
                                onClick={() => setTransactionForAlerts(transaction)}
                            />
                        </div>
                    ) : (
                        <span className="action-empty-placeholder">{constants.emptyPlaceholder}</span>
                    )}
                </div>
                <div className="action-col">
                    {showHistory ? (
                        <ImSubscriptionActionBlocker>
                            {blocked => (
                                <div onClick={e => e.stopPropagation()}>
                                    <TransactionHistoryLink
                                        referenceName={referenceName}
                                        disabled={blocked}
                                        dealReferenceName={dealReferenceName}
                                        label={false}
                                        version={version}
                                    />
                                </div>
                            )}
                        </ImSubscriptionActionBlocker>
                    ) : (
                        <span className="action-empty-placeholder">{constants.emptyPlaceholder}</span>
                    )}
                </div>
                <div className="action-col">
                    <IntexButton
                        transaction={transactionWithClass}
                        userCompany={userCompany}
                        onClick={setTransactionForIntex}
                        placeholderIfEmpty
                        secondaryPlaceholderColor
                    />
                </div>
                <div className="action-col action-col-bwic-monitor-link">
                    <BwicMonitorLink
                        currency={transaction.currency}
                        identifiers={classIdentifiers}
                        secondaryPlaceholderColor
                    />
                </div>
                <div className="action-col action-col-bwic-inventory-link">
                    <BwicInventoryLink
                        currency={transaction.currency}
                        identifiers={classIdentifiers}
                        secondaryPlaceholderColor
                    />
                </div>
                <div className="action-col">
                    <AddToPortfolioLink
                        transaction={transaction}
                        transactionClass={transactionClass}
                        checkSubscription
                    />
                </div>
                <div className="action-col action-col-bloomberg">
                    <BloombergLink identifiers={classIdentifiers} placeholderIfEmpty secondaryPlaceholderColor />
                </div>
                <div className="action-col action-col-valitana">
                    <ValitanaLink identifiers={classIdentifiers} placeholderIfEmpty secondaryPlaceholderColor />
                </div>
            </div>
        );
    },
    headerClassName: "data-list-cell-actions-wrap padding-r-0 data-list-cell-actions text-right",
    bodyClassName: "data-list-cell-actions-wrap padding-r-0 data-list-cell-actions text-right",
    stickRight: true,
};

export const optionsButtonColumnDefinition: IColumnDefinition<
    OriginatingTransactionClass | AmrClass
> = {
    columnKey: PipelineColumn.optionButton,
    renderColumnHeaderContent: () => "",
    renderColumnContent: (
        transactionClass,
        context: {
            userCompany: Company;
        }
    ) => {
        const { userCompany } = context;
        const { transaction, originatingTransactionClassStatus, numberOfIOIs } =
            transactionClass as OriginatingTransactionClass;

        const isAllowedClassStatus = ![
            OriginatingTransactionClassStatus.NotOffered,
            OriginatingTransactionClassStatus.Subject,
        ].includes(originatingTransactionClassStatus);

        if (!isAllowedClassStatus) {
            return null;
        }

        return (
            <span onClick={(e) => e.stopPropagation()}>
                <OptionsButton
                    transaction={transaction}
                    userCompany={userCompany}
                    withIOIs={!!numberOfIOIs}
                />
            </span>
        );
    },
    headerClassName: "data-list-cell-sm-01 padding-l-0 data-list-cell-actions-btn text-right",
    bodyClassName: "data-list-cell-sm-01 padding-l-0 data-list-cell-actions-btn text-right",
    stickRight: true,
};
