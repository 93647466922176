import { AlertOption } from "../../../../types/email-preferences/EmailPreferences";

export enum FilterSaveMode {
    Create,
    Update
}

export enum FilterItemAction {
    MakeDefault,
    ResetDefault,
    DiscardChanges,
    Delete,
    SetFilterForPopup,
}

export interface FilterManagementForm {
    mode: FilterSaveMode;
    referenceName?: string;
    name: string;
    makeDefault: boolean;
    receiveEmailNotification: AlertOption;
    bwicAlert?: AlertOption;
    inventoryAlert?: AlertOption;
    issuanceMonitorAlert?: AlertOption;
    rollerDeadlineAlert?: AlertOption;
}
