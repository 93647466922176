import React from 'react';
import { PipelineType } from '../../../types/amr-pipeline/enums/PipelineType';
import { IssuanceMonitorTab } from '../types/PipelineTabTypes';

type FilterCollapseStatus = {
    [key in PipelineType]: boolean;
}

type OptionColumnCollapseStatus = {
    [key in PipelineType.Deals | PipelineType.IOIs]: boolean;
}

interface PipelineContextProps {
    pipelineType: PipelineType;
    filtersCollapseStatus: FilterCollapseStatus;
    optionColumnCollapseStatus: OptionColumnCollapseStatus;
    setFiltersCollapseStatus: (isCollapsed: boolean) => void;
    setOptionColumnCollapseStatus: (isCollapsed: boolean) => void;
    setTab?: (tab: IssuanceMonitorTab) => void;
    withImSubscription: boolean;
    withBlockedSearchAndFilter?: boolean;
}

const PipelineContext = React.createContext<PipelineContextProps>({
    pipelineType: PipelineType.Deals,
    filtersCollapseStatus: {
        [PipelineType.Deals]: false,
        [PipelineType.IOIs]: false,
        [PipelineType.ArrangerPipeline]: false
    },
    optionColumnCollapseStatus: {
        [IssuanceMonitorTab.Deals]: false,
        [IssuanceMonitorTab.IOIs]: false
    },
    setOptionColumnCollapseStatus: (status: boolean) => null,
    setFiltersCollapseStatus: (status: boolean) => null,
    withImSubscription: false,
    withBlockedSearchAndFilter: false,
});

export default PipelineContext;
