import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorActions } from '../../actions';
import { amrPipelineService } from '../../services/amr-pipeline.service';
import { StatusMessageSectionType } from '../../types/state/NotificationState';
import { FlexRow } from '../common';
import { Popup, PopupBody, PopupFooter } from '../controls';
import { StatusMessageSection } from '../status-message/StatusMessageSection';

export const RequestFullDemoButton = () => {
    const dispatch = useDispatch();
    const [showPopup, setShowPopup] = useState(false);

    const handleClick = async () => {
      try {
          await amrPipelineService.requestDemo();
          setShowPopup(true);
      } catch (error) {
          dispatch(errorActions.error(error))
      }
    }

    return (
        <>
            <button className="btn btn-ghost btn-invite-clients" onClick={handleClick}>
                Request Full Demo
            </button>
            {showPopup && (
                <Popup
                    title="Request has been submitted"
                    modalClass="modal-clients-invite"
                    onClose={() => setShowPopup(false)}
                >
                    <PopupBody>
                        <StatusMessageSection type={StatusMessageSectionType.Info}>
                            Your request for a full demo has been submitted to the KTX ATS platform sales team. We will
                            reach out soon.
                        </StatusMessageSection>
                    </PopupBody>
                    <PopupFooter>
                        <FlexRow>
                            <button type="button" className="btn btn-ghost" onClick={() => setShowPopup(false)}>
                                Close
                            </button>
                        </FlexRow>
                    </PopupFooter>
                </Popup>
            )}
        </>
    );
};
