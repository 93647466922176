import { OriginatingTransaction } from "../../../types/amr-pipeline/models/OriginatingTransaction";
import { routes } from "../../../constants";
import { TransactionStatus } from "../../../types/amr-pipeline/enums/TransactionStatus";
import { Link } from "react-router-dom";
import { TransactionsTabTypes } from "../types/TransactionsTabTypes";
import { Company } from "../../../types/amr-pipeline/models/Company";
import { TransactionType } from "../../../types/amr-pipeline/enums/TransactionType";
import { canSubmitIOIs, canViewIOIs } from "../../../utils/amr-pipeline.utils";
import { Transaction } from '../../../types/amr-pipeline/models/Transaction';

interface OptionsButtonProps {
    transaction: Transaction;
    userCompany: Company;
    withIOIs?: boolean;
}

export const OptionsButton = ({ transaction, userCompany, withIOIs }: OptionsButtonProps) => {
    const { referenceName, dealReferenceName, status } = transaction;

    if (transaction.type === TransactionType.amr) {
        return null;
    }

    const notActiveTransaction = status !== TransactionStatus.Active;
    const userCanViewIOIs = canViewIOIs(transaction, userCompany);
    const userCanSubmitIOIs = canSubmitIOIs(transaction);

    const ioisLink = routes.transactionDetailUrl(
        referenceName,
        dealReferenceName,
        TransactionsTabTypes.IOIs
    );

    if (userCanSubmitIOIs) {
        return (
            <Link className="btn btn-main btn-sm text-transform-none btn-view-submit-iois" to={ioisLink} target="_blank">
                SUBMIT IOIs
            </Link>
        );
    }

    const isViewButtonDisabled = notActiveTransaction &&
        !(withIOIs || (transaction as OriginatingTransaction).classes.some(c => c.numberOfIOIs));

    if (isViewButtonDisabled) {
        return null;
    }

    if (userCanViewIOIs) {
        return (
            <Link className="btn btn-ghost btn-sm text-transform-none btn-view-submit-iois" to={ioisLink} target="_blank">
                VIEW IOIs
            </Link>
        );
    }

    return null;
};
