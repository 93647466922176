import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { roles } from '../../../../constants';
import { DownloadTemplateButton } from '../../../common/download-template/DownloadTemplateButton';
import { Breadcrumbs } from '../../../controls/breadcrumbs/Breadcrumbs';
import { BreadcrumbsItem } from '../../../controls/breadcrumbs/BreadcrumbsItem';
import { AppState } from '../../../../types/state/AppState';
import PipelineContext from '../PipelineContext';
import { IssuanceMonitorTab } from '../../types/PipelineTabTypes';
import { amrArrangerPipelineActions } from '../../../../actions/amr-arranger-pipeline.actions';
import { gridActions } from '../../../../actions';
import { Grid } from '../../../grid';
import { arrangerPipelineDealsSelector } from '../../../../selectors/amr-pipeline.selector';
import { DeleteAllButton } from '../../../grid/DeleteAllButton';
import { DeleteRowsWithErrorButton } from '../../../grid/DeleteRowsWithErrorButton';
import { RouteLeavingGuard } from '../../../routing';
import { ArrangerPipeline } from '../../../../types/amr-pipeline/models/ArrangerPipeline';
import { GridDataItem, GridUploadStatus } from '../../../../types/state/GridState';
import { UnsavedChangesPopup } from '../../../routing/UnsavedChangesPopup';
import { ShowFor } from '../../../access';
import { user } from '../../../../user';
import { Preloader } from '../../../common';
import { useHistory } from 'react-router';

export const ArrangerPipelineEdit = () => {
    const { setTab } = useContext(PipelineContext);
    const dispatch = useDispatch();

    const history = useHistory();

    const isGridUploading = useSelector(
        (state: AppState) =>
            state.grid.upload.status === GridUploadStatus.Uploading ||
            state.grid.upload.status === GridUploadStatus.UploadingSingle,
    );
    const { isSubmitting, isSubmitted, arrangerPipelines, isLoading } = useSelector(arrangerPipelineDealsSelector);
    const dataItems = useSelector((state: AppState) => state.grid.dataItems);

    const [showRouteLeavingPopup, setShowRouteLeavingPopup] = useState(false);

    const nonDraftDataItems = dataItems.filter(d => !d.draft);
    const hasErrors = dataItems.some(c => !c.draft && c.errors && c.errors.length);

    const isBD = user.hasRoles(...roles.bd());

    useEffect(() => {
        dispatch(amrArrangerPipelineActions.edit());

        return () => {
            dispatch(gridActions.reset());
        };
    }, [dispatch]);

    useEffect(() => {
        if (isSubmitted && setTab) {
            setTab(IssuanceMonitorTab.ArrangerPipeline);
            dispatch(amrArrangerPipelineActions.submitArrangerPipelineStatus(false));
        }
    }, [dispatch, isSubmitted, setTab]);

    const hasChanges = () => {
        const isChangedOrRemoved = (original: ArrangerPipeline, edit?: GridDataItem<ArrangerPipeline>) => {
            if (!edit) return true;

            return (
                original.type !== edit.type ||
                original.reinvestmentPeriodEnd !== edit.reinvestmentPeriodEnd ||
                original.nonCallPeriodEnd !== edit.nonCallPeriodEnd ||
                original.arranger?.referenceName !== edit.arrangerReferenceName ||
                original.collateralManager.referenceName !== edit.collateralManagerReferenceName ||
                original.anchor !== edit.anchor ||
                original.equity !== edit.equity ||
                original.dealStage !== edit.dealStage ||
                original.expectedTiming !== edit.expectedTiming ||
                original.dealLegalName !== edit.dealLegalName ||
                original.euCompliance !== edit.euCompliance ||
                original.collateralType !== edit.collateralType ||
                original.expectedTiming !== edit.expectedTiming ||
                original.dealStage !== edit.dealStage ||
                original.disclosureFile !== edit.disclosureFile
            );
        }

            return (
                arrangerPipelines?.some(p =>
                    isChangedOrRemoved(
                        p,
                        nonDraftDataItems.find(i => i.referenceName === p.referenceName),
                    ),
                ) ||
                (arrangerPipelines && nonDraftDataItems.length > arrangerPipelines.length)
            );
    }


    const handleSave = () => {
        dispatch(amrArrangerPipelineActions.submitArrangerPipeline());
    };

    const handleCancel = () => {
        if (hasChanges()) {
            setShowRouteLeavingPopup(true);
            return;
        }

        setTab && setTab(IssuanceMonitorTab.ArrangerPipeline);
    };

    return (
        <div className="arranger-pipeline-edit container">
            <RouteLeavingGuard
                navigate={(pathname: string) => history.push(pathname)}
                shouldBlockNavigation={() => hasChanges()}
            />
            {showRouteLeavingPopup && (
                <UnsavedChangesPopup
                    onConfirm={() => setTab && setTab(IssuanceMonitorTab.ArrangerPipeline)}
                    onCancel={() => setShowRouteLeavingPopup(false)}
                />
            )}
            <div className="sub-header">
                <div className="sub-header-row type01 flex-row position-relative">
                    <Breadcrumbs>
                        <BreadcrumbsItem>
                            <span onClick={handleCancel} className="btn-link secondary regular">
                                Arranger's Pipeline
                            </span>
                        </BreadcrumbsItem>
                    </Breadcrumbs>
                    <h1>Edit List</h1>

                    <div className="flex-item-right controls">
                        <DeleteRowsWithErrorButton />
                        <DeleteAllButton disabled={isGridUploading} />
                        <ShowFor roles={[roles.Administrator, roles.DataEntry]}>
                            <DownloadTemplateButton templateType="SecuritiesXlsxTemplate" disabled={isGridUploading} />
                        </ShowFor>
                        <button
                            type="button"
                            className="btn btn-ghost"
                            onClick={handleCancel}
                            disabled={isGridUploading}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="btn btn-main"
                            disabled={isSubmitting || isGridUploading || !!hasErrors}
                            onClick={handleSave}
                        >
                            SAVE
                        </button>
                    </div>
                </div>
            </div>
            <Preloader inProgress={isLoading}>
                <Grid dataUploadDisabled={isBD} dataItems={dataItems} helpPopoverTitle="You can add your securities in the following ways" />
            </Preloader>
        </div>
    );
};
